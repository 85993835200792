import { notifyError, notifySuccess, notifyWarning } from '@/components'
import {
  useAddSubscriptionMutation,
  useDeleteWebPushSubscriptionMutation,
} from '@/modules/sidebar/services/notificationService'
import { urlBase64ToUint8Array } from '@/utils/serviceWorker'
import { Box, Button, CircularProgress } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

interface Props {
  isSend: boolean
  subscriptionsArr: string[]
}

const NotificationComponent: FC<Props> = ({ isSend, subscriptionsArr }) => {
  const [loading, setLoading] = useState(false)
  const [haveSubscription, setHaveSubscription] = useState(false)

  const [saveSubscriptionService] = useAddSubscriptionMutation()
  const [deleteSubscriptionService] = useDeleteWebPushSubscriptionMutation()

  useEffect(() => {
    ;(async () => {
      const registration = await navigator.serviceWorker.getRegistration()
      const subscription = await registration?.pushManager.getSubscription()
      const isHaveSubscription = subscription?.endpoint
        ? subscriptionsArr.includes(subscription.endpoint)
        : false

      if (isHaveSubscription) {
        setHaveSubscription(true)
      } else {
        setHaveSubscription(false)
      }
    })()
  }, [subscriptionsArr])

  const onGetSubscription = async () => {
    try {
      setLoading(true)
      const result = await Notification.requestPermission()
      if (result === 'denied') {
        notifyWarning(
          'Пользователь отменил запрос на получение уведомлений',
          3000
        )
        setLoading(false)
        return
      }
      if (result === 'granted') {
        const registration = await navigator.serviceWorker.getRegistration()
        const subscription = await registration?.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            import.meta.env.VITE_VAPID_PUBLIC_KEY
          ),
        })
        if (subscription) {
          const subscriptionString = JSON.stringify(subscription)
          const body = {
            subscription: subscriptionString,
            Browser: navigator.userAgent,
          }
          const res = await saveSubscriptionService(body as any).unwrap()
          if (res.success) {
            setHaveSubscription(true)
            setLoading(false)
            notifySuccess('Push уведомления включены')
          }
        }
      }
    } catch (e) {
      console.log(e)
      notifyError('Что-то пошло не так')
    } finally {
      setLoading(false)
    }
  }

  const onDeleteSubscription = async () => {
    try {
      setLoading(true)
      const registration = await navigator.serviceWorker.getRegistration()
      const subscription = await registration?.pushManager.getSubscription()
      if (subscription) {
        await subscription?.unsubscribe()
        const endpoint = JSON.stringify(subscription.endpoint)
        const body = { endpoint: endpoint }
        await deleteSubscriptionService(body as any).unwrap()
        setHaveSubscription(false)
        setLoading(false)
        notifySuccess('Push уведомления выключены')
      }
    } catch (e) {
      console.log(e)
      notifyError('Что-то пошло не так')
    } finally {
      setLoading(false)
    }
  }

  if (!('serviceWorker' in navigator && 'PushManager' in window)) {
    return null
  }

  if (!isSend) {
    return null
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
    >
      {loading ? (
        <CircularProgress size={15} />
      ) : (
        <>
          {haveSubscription ? (
            <Button
              variant='contained'
              color='red'
              onClick={onDeleteSubscription}
            >
              Удалить подписку
            </Button>
          ) : (
            <Button
              variant='contained'
              color='green'
              onClick={onGetSubscription}
            >
              Оформить подписку
            </Button>
          )}
        </>
      )}
    </Box>
  )
}

export default NotificationComponent
