import { FC } from 'react'
import IconSuccess from '@/components/Atoms/Icons/IconRoundSuccess'
import IconTerminal from '@/components/Atoms/Icons/IconTerminal'
import IconWarning from '@/components/Atoms/Icons/IconTriangleWarning'
import IconInfo from '@/components/Atoms/Icons/IconTriangleInfo'
import IconError from '@/components/Atoms/Icons/IconTriangleError'
type TypeIcons = {
  id: number
  width?: number
  height?: number
}
const IconsSelector: FC<TypeIcons> = ({ id, height, width }) => {
  return (
    <>
      {id === 1 && (
        <IconSuccess
          height={height}
          width={width}
        />
      )}
      {id === 2 && (
        <IconWarning
          height={height}
          width={width}
        />
      )}
      {id === 3 && (
        <IconError
          height={height}
          width={width}
        />
      )}
      {id === 4 && (
        <IconInfo
          height={height}
          width={width}
        />
      )}
      {id === 101 && <IconTerminal />}
    </>
  )
}

export default IconsSelector
