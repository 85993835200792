import CustomAutocompleteRedux from '@/components/CustomAutocomplete/CustomAutocompleteRedux'
import { useActions, useAppSelector } from '@/hooks'
import {
  deviceStateFilter,
  filterSystemSelector,
  fScreenSelector,
} from '@/store/filterSystemsSlise'
import CropFreeIcon from '@mui/icons-material/CropFree'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import GridViewIcon from '@mui/icons-material/GridView'
import SearchIcon from '@mui/icons-material/Search'
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline'
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined'
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
} from '@mui/material'
import { Popover } from 'antd'
import * as React from 'react'
import { Dispatch, FC, SetStateAction } from 'react'
import { useGetTypesUnitsForUserQuery } from '../../services/newUnitsListService'
import ColumnSlider from '../DevicesTable/ColumnSlider'
import xls from '@/assets/img/xls.png'
import axios from 'axios'
import dayjs from 'dayjs'
import FileDownload from 'js-file-download'
import Filter from '@/modules/devices-list/components/Header/Filter'

interface IHeader {
  isMap?: boolean
  isGridView?: boolean
  setGridView?: (value: boolean) => void
  columns?: number
  setColumns?: Dispatch<SetStateAction<number>>
  setIsFullScreen?: Dispatch<SetStateAction<boolean>>
  isSHowGridBtn?: boolean
}

const Header: FC<IHeader> = ({
  isMap,
  isSHowGridBtn,
  isGridView,
  setGridView,
  setIsFullScreen,
  columns,
  setColumns,
}) => {
  const { filter, searchValue, isSearch, typeUnits, favoriteId } =
    useAppSelector(filterSystemSelector)
  const { setSearchValue, setIsSearch, setFilter } = useActions()
  const { data: units, isLoading } = useGetTypesUnitsForUserQuery()

  const isFScreen = useAppSelector(fScreenSelector)
  const { toggleFScreen } = useActions()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [isLoadingDownload, setIsLoadingDownload] = React.useState(false)
  const stateFilter = useAppSelector(deviceStateFilter)
  const handleClose = async (e: any) => {
    if (e.target.nodeName !== 'LI' && !e.target.id) {
      setAnchorEl(null)
      return
    }
    try {
      setIsLoadingDownload(true)
      setAnchorEl(null)
      const data = {
        value: !searchValue.trim().length ? '' : searchValue,
        deviceTypeFilter: filter,
        deviceStateFilter: stateFilter,
        formatTypeId: e.target.id === 'pdf' ? 2 : (1 as 1 | 2),
        favoriteId,
      }
      const res = await axios({
        url: `${import.meta.env.VITE_API_URL}/api/devices/reportGeneral`,
        method: 'POST',
        data: data,
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
        },
      })
      const date = dayjs(new Date()).format('YYYYMMDD')
      FileDownload(res.data, `reportAll_${date}.${e.target.id}`)
      setIsLoadingDownload(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingDownload(false)
    }
  }

  return (
    <Stack
      direction={{ xss: 'column', xs: 'row' }}
      justifyContent='space-between'
      alignItems='center'
      pt={{ xss: 0, md: 1 }}
      pl={1}
      gap={{ xss: 0, md: 2 }}
      sx={{
        backgroundColor: 'transparent',
        position: isMap ? 'absolute' : 'unset',
        top: { xss: 7, sm: 8, md: -5 },
        left: { xss: 5, sm: 10, md: 10 },
        zIndex: 1,
      }}
    >
      <Box
        display='flex'
        flexDirection={{ xss: 'column', xs: 'row' }}
        gap={{ xss: 0, md: 2 }}
      >
        <Box
          width={{ xss: 140, xs: 140, sm: 140, md: 280, lg: 350 }}
          border='1px solid #0072BC'
          borderRadius={1}
          mr={{ xss: 0, xs: 1, sm: 0 }}
        >
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
            className={isMap ? 'is_map_input' : ''}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, height: 44 }}
              placeholder='Поиск системы'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (
                  e.code === 'Enter' ||
                  e.charCode === 13 ||
                  e.code === 'NumpadEnter'
                ) {
                  e.preventDefault()
                  setIsSearch(!isSearch)
                }
              }}
              inputProps={{
                maxLength: 50,
              }}
            />
            <IconButton
              onClick={() => setIsSearch(!isSearch)}
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box
          width={{
            xss: 140,
            sm: 140,
            md: 280,
            lg: 350,
            xlg: isMap ? 400 : 400,
            xl: isMap ? 400 : 400,
          }}
        >
          <CustomAutocompleteRedux
            label='Фильтр систем'
            options={(favoriteId ? typeUnits : units) ?? []}
            filter={filter}
            setFilter={setFilter}
            nameForStorage='filterValueDevices'
            isMap
          />
        </Box>
        <Box>
          <Filter />
        </Box>
      </Box>
      <Box>
        {setGridView ? (
          <>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ maxHeight: 450 }}
            >
              <MenuList>
                <MenuItem
                  id='pdf'
                  onClick={handleClose}
                >
                  .pdf
                </MenuItem>
                <MenuItem
                  id='xlsx'
                  onClick={handleClose}
                >
                  .xlsx
                </MenuItem>
              </MenuList>
            </Menu>
            {isLoadingDownload ? (
              <CircularProgress
                size={25}
                color='white'
              />
            ) : (
              <IconButton onClick={handleClick}>
                <img
                  src={xls}
                  alt='XLS'
                  width={25}
                  height={25}
                />
              </IconButton>
            )}
          </>
        ) : null}
        {setGridView ? (
          <>
            {isSHowGridBtn ? (
              <>
                {isGridView ? null : (
                  <>
                    <Popover
                      id='popove-slider'
                      placement='bottomRight'
                      content={
                        <ColumnSlider
                          columns={columns ?? 2}
                          setColumns={setColumns}
                        />
                      }
                      trigger='click'
                    >
                      <IconButton>
                        <ViewQuiltOutlinedIcon />
                      </IconButton>
                    </Popover>
                    <IconButton
                      onClick={() => {
                        if (setIsFullScreen) {
                          toggleFScreen()
                          //setIsFullScreen(true)
                        }
                      }}
                    >
                      {isFScreen ? <FullscreenExitIcon /> : <CropFreeIcon />}
                    </IconButton>
                  </>
                )}
                <IconButton
                  sx={{ mr: { xss: 0, md: 2 } }}
                  onClick={() => setGridView(!isGridView)}
                >
                  {isGridView ? <GridViewIcon /> : <ViewHeadlineIcon />}
                </IconButton>
              </>
            ) : null}
          </>
        ) : null}
      </Box>
    </Stack>
  )
}

export default Header
