import type { Program, Slide } from './types'
export const getActiveProgram = (
  id: number | null,
  programList: Program[]
): Program | undefined => {
  return programList.find((item) => item.index === id)
}
export const getActiveSlide = (
  id: number | null,
  slidesList: Slide[] | undefined
): Slide => {
  // @ts-ignore: Unreachable code error
  return slidesList?.find((item) => item.id === id)
}
export const getActiveSlideItems = (id: number, programList: Program[]) => {}
