import { Box, Grid, Stack } from '@mui/material'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useChangeOrganizationStatusMutation,
  useGetOrganizationProfileQuery,
  useUpdateOrganizationProfileMutation,
} from '@/modules/organization/services/organizationService'
import * as Yup from 'yup'
import { stringToNumber } from '@/modules/user/utils/phoneToNumber'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Input from '../Input/Input'
import PhoneInput from '../Input/PhoneInput'
import { LoadingButton } from '@mui/lab'
import { Dialog, notifyError, notifySuccess } from '@/components'
import { useChangeSet } from '@/utils/useChangeSet'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

export interface IOrganizationDataFields {
  email: string
  name: string
  itn: number | null | string
  address: string
  siteUrl: string | null
  contactName: string
  contactEmail: string
  contactPhone: string
  phone: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Название организации не может быть пустым')
    .trim('Название организации не может быть пустым')
    .max(50, 'Поле должно содержать не более 50-ти символов'),
  address: Yup.string()
    .required('Адрес организации не может быть пустым')
    .trim('Адрес организации не может быть пустым')
    .max(250, 'Поле должно содержать не более 250 символов'),
  itn: Yup.number()
    .required('ИНН организации не может быть пустым')
    .test(
      'len',
      'ИНН должно содержать 12 цифр',
      (val) => val?.toString().length === 12
    )
    .typeError('ИНН состоит из 12 цифр'),
  phone: Yup.string()
    .transform((value, origValue) => {
      return origValue === '+_(___)___-__-__'
        ? '00000000000'
        : stringToNumber(origValue || '00000000000')
    })
    .min(11, 'Некорректный Телефон. Формат +7(999)999-99-99'),
  contactPhone: Yup.string()
    .transform((value, origValue) => {
      return origValue === '+_(___)___-__-__'
        ? '00000000000'
        : stringToNumber(origValue || '00000000000')
    })
    .min(11, 'Некорректный Телефон. Формат +7(999)999-99-99'),
  email: Yup.string()
    .email('Некорректный формат Email')
    .max(50, 'Длина Email должна быть не более 50-ти символов'),
  contactEmail: Yup.string()
    .email('Некорректный формат Email')
    .max(50, 'Длина Email должна быть не более 50-ти символов'),
  siteUrl: Yup.string().max(
    50,
    'Поле должно содержать не более 50-ти символов'
  ),
  contactName: Yup.string().max(
    50,
    'Поле должно содержать не более 50-ти символов'
  ),
})

interface IOrganizationDataProps {
  setLoader: Dispatch<SetStateAction<boolean>>
}

const OrganizationData: FC<IOrganizationDataProps> = ({ setLoader }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const isNewOrganization = id === 'new'

  const { data: OrganizationData, isError } = useGetOrganizationProfileQuery(
    id ?? '0',
    {
      skip: isNewOrganization,
      refetchOnMountOrArgChange: true,
    }
  )

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const [
    updateOrganizationProfileService,
    { isLoading: isLoadingUpdateOrganization },
  ] = useUpdateOrganizationProfileMutation()
  const [changeStatusService, { isLoading: isLoadingChangeStatus }] =
    useChangeOrganizationStatusMutation()

  const [openDialogSaveOrganization, setOpenDialogSaveOrganization] =
    useState(false)
  const [openDialogDeleteOrganization, setOpenDialogDeleteOrganization] =
    useState(false)
  const [openDialogActivateOrganization, setOpenDialogActivateOrganization] =
    useState(false)

  const defaultValues: IOrganizationDataFields = {
    email: '',
    name: '',
    itn: null,
    address: '',
    siteUrl: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    phone: '',
  }

  const { handleSubmit, control, reset, trigger, formState, watch, getValues } =
    useForm<IOrganizationDataFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      reValidateMode: 'onChange',
    })

  useEffect(() => {
    if (
      currentUserData?.data?.typeId === UserTypes.admin &&
      Number(id) !== currentUserData?.data?.organizationId
    ) {
      navigate(-1)
    }
  }, [currentUserData?.data?.typeId, id, currentUserData?.data?.organizationId])

  useEffect(() => {
    if (OrganizationData?.success === false || isError) {
      navigate(-1)
    }
    if (isNewOrganization) {
      reset({
        email: '',
        name: '',
        itn: null,
        address: '',
        siteUrl: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        phone: '',
      })
    } else {
      reset({
        email: OrganizationData?.data?.email,
        name: OrganizationData?.data?.name,
        itn: OrganizationData?.data?.itn
          ? Number(OrganizationData?.data?.itn)
          : null,
        address: OrganizationData?.data?.address,
        siteUrl: OrganizationData?.data?.siteUrl,
        contactName: OrganizationData?.data?.contactName,
        contactEmail: OrganizationData?.data?.contactEmail,
        contactPhone: OrganizationData?.data?.contactPhone,
        phone: OrganizationData?.data?.phone,
      })
    }
  }, [id, OrganizationData])

  const onSaveBtnClick = () => {
    trigger().then(() => {
      if (!getValues('itn') || !getValues('address') || !getValues('name')) {
        return
      }
      if (
        !formState.errors.email &&
        !formState.errors.name &&
        !formState.errors.itn &&
        !formState.errors.address &&
        !formState.errors.siteUrl &&
        !formState.errors.contactName &&
        !formState.errors.contactEmail &&
        !formState.errors.phone &&
        !formState.errors.contactPhone
      ) {
        setOpenDialogSaveOrganization(true)
      }
    })
  }

  const onChangeOrganizationStatus = async (status: boolean) => {
    try {
      setOpenDialogDeleteOrganization(false)
      setOpenDialogActivateOrganization(false)
      setLoader(true)
      const res = await changeStatusService({ id: Number(id), status }).unwrap()
      if (res.success) {
        status
          ? notifySuccess('Организация активирована')
          : notifySuccess('Организация деактивирована')
      }
      setLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async (data: IOrganizationDataFields) => {
    try {
      setLoader(true)
      setOpenDialogSaveOrganization(false)
      data.phone = data.phone === '00000000000' ? '' : data.phone
      data.contactPhone =
        data.contactPhone === '00000000000' ? '' : data.contactPhone
      data.itn = data.itn ? data.itn.toString() : ''
      const res = await updateOrganizationProfileService({
        id: isNewOrganization ? 0 : (OrganizationData?.data?.id ?? Number(id)),
        data: data,
      }).unwrap()
      if (res.success) {
        isNewOrganization
          ? notifySuccess('Организация создана')
          : notifySuccess('Организация обновлена')
        if (isNewOrganization) {
          navigate(`/organizations/${res?.id}`)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoader(false)
    }
  }

  useChangeSet(() => {
    if (watch('email')?.length) {
      trigger('email')
    }
    if (watch('name')?.length) {
      trigger('name')
    }
    if (watch('itn')) {
      trigger('itn')
    }
    if (watch('address')?.length) {
      trigger('address')
    }
    if (watch('siteUrl')?.length) {
      trigger('siteUrl')
    }
    if (watch('contactName')?.length) {
      trigger('contactName')
    }
    if (watch('contactPhone')?.length) {
      trigger('contactPhone')
    }
    if (watch('phone')?.length) {
      trigger('phone')
    }
    if (watch('contactEmail')?.length) {
      trigger('contactEmail')
    }
  }, [
    watch('email'),
    watch('name'),
    watch('itn'),
    watch('address'),
    watch('siteUrl'),
    watch('contactName'),
    watch('contactEmail'),
    watch('contactPhone'),
    watch('phone'),
  ])

  return (
    <Box
      position='relative'
      padding={{ xss: 0, md: 2 }}
      sx={{ width: '100%', mt: 3, pb: 2 }}
    >
      <Dialog
        message='Изменения будут сохранены, продолжить?'
        open={openDialogSaveOrganization}
        handleClose={() => setOpenDialogSaveOrganization(false)}
        handleAgree={() => {
          if (Object.keys(formState.errors).length >= 1) {
            notifyError('Исправте ошибки в форме')
          }
          handleSubmit(onSubmit)()
        }}
      />
      <Dialog
        message='Данная организация будет деактивирована. Продолжить?'
        open={openDialogDeleteOrganization}
        handleClose={() => setOpenDialogDeleteOrganization(false)}
        handleAgree={() => onChangeOrganizationStatus(false)}
      />
      <Dialog
        message='Вы действительно хотите активировать организацию?'
        open={openDialogActivateOrganization}
        handleClose={() => setOpenDialogActivateOrganization(false)}
        handleAgree={() => onChangeOrganizationStatus(true)}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 5, xxl: 6 }}
        >
          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='column'
              gap={2}
            >
              <Input
                name='name'
                type='text'
                label='Название организации'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <Input
                multiline
                maxRows={5}
                minRows={2}
                name='address'
                type='text'
                label='Адрес организации'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <Input
                name='itn'
                type='number'
                label='ИНН организации / ИП'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <Input
                name='siteUrl'
                type='text'
                label='Сайт организации'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <Input
                name='email'
                type='text'
                label='Email организации'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <PhoneInput
                name='phone'
                control={control}
                label='Телефон организации'
                disabled={isLoadingUpdateOrganization}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xss={12}
            lg={6}
          >
            <Stack
              direction='column'
              gap={2}
            >
              <Input
                name='contactName'
                type='text'
                label='Контактное лицо'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <Input
                name='contactEmail'
                type='text'
                label='Электронная почта контактного лица'
                control={control}
                disabled={isLoadingUpdateOrganization}
              />
              <PhoneInput
                name='contactPhone'
                control={control}
                label='Телефон контактного лица'
                disabled={isLoadingUpdateOrganization}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
          mt={4}
        >
          {isNewOrganization || currentUserType === UserTypes.admin ? null : (
            <>
              {OrganizationData?.data?.deleted ? (
                <LoadingButton
                  loading={isLoadingChangeStatus || isLoadingUpdateOrganization}
                  variant='outlined'
                  color='error'
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDialogActivateOrganization(true)
                  }}
                >
                  <span>Активировать</span>
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isLoadingChangeStatus || isLoadingUpdateOrganization}
                  variant='outlined'
                  color='error'
                  onClick={(e) => {
                    e.preventDefault()
                    setOpenDialogDeleteOrganization(true)
                  }}
                >
                  <span>Деактивировать</span>
                </LoadingButton>
              )}
            </>
          )}
          <LoadingButton
            loading={isLoadingChangeStatus || isLoadingUpdateOrganization}
            variant='outlined'
            onClick={onSaveBtnClick}
            disabled={!formState.isDirty}
          >
            <span>Сохранить</span>
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export default OrganizationData
