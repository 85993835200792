import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { FC } from 'react'

interface IDialog {
  open: boolean
  handleClose: () => void
  handleAgree: () => void
  handleDisAgree: () => void
  message?: string
  title?: string
  loading?: boolean
  btnSaveTitle?: string
  btnCancelTitle?: string
}

const DialogChanges: FC<IDialog> = ({
  open,
  handleClose,
  message,
  handleAgree,
  handleDisAgree,
  title,
  loading,
  btnSaveTitle = 'Сохранить',
  btnCancelTitle = 'Отменить',
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      {message ? (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleDisAgree}>{btnCancelTitle}</Button>
        <LoadingButton
          onClick={handleAgree}
          autoFocus
          loading={loading}
          variant={'contained'}
        >
          {btnSaveTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogChanges
