export function dataURItoBlob(b64Data: string, name: string, sliceSize = 512) {
  if (!b64Data) {
    return null
  } else {
    const base64 = b64Data.split(';')
    const contentType = base64[1].split(',')[1]
    const byteCharacters = atob(contentType)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new File(byteArrays, `${name + Date.now()}.png`, {
      type: 'image/png',
    })
  }
}

export function dataURLtoBlob(dataURL: string) {
  const parts = dataURL.split(',')
  const contentType = parts[0].split(':')[1]
  const byteString = atob(parts[1])

  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }

  return new Blob([arrayBuffer], { type: contentType })
}
