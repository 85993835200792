import {
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  TextField,
  useMediaQuery,
} from '@mui/material'
import IconAttach from '@/components/Atoms/Icons/IconAttach'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  useGetDescriptionQuery,
  useUpdateNotesNewMutation,
} from '../../services/deviceService'
import styles from './NotesForm.module.scss'

export const useNotesForm = () => {
  const { id } = useParams()
  const { data } = useGetDescriptionQuery(Number(id))
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  useEffect(() => {
    setError(false)
    setErrorMsg('')
  }, [data])

  return { data, error, errorMsg, setError, setErrorMsg }
}

const cropName = (name: string) => {
  return name.substring(name.length - 15)
}

const NotesForm = () => {
  const { register, handleSubmit, reset } = useForm()
  const isMobile = useMediaQuery('(max-width:768px)')
  const { id } = useParams()
  const [addNoteService, { isLoading, isError }] = useUpdateNotesNewMutation()
  const { error, errorMsg, setError, setErrorMsg } = useNotesForm()
  const [filesList, setFilesList] = useState<File[]>([])
  const onSubmit = async (data: any) => {
    const { message } = data
    if (message.trim() === '') {
      setError(true)
      setErrorMsg('Сообщение не может быть пустым')
      return
    }
    if (message.length > 400) {
      setError(true)
      setErrorMsg('Сообщение должно содержать не более 400 символов')
      return
    }

    try {
      const formData = new FormData()
      formData.append('deviceId', id || '')
      formData.append('message', message)
      for (let i = 0; i < filesList.length; i++) {
        formData.append('noteImage', filesList[i])
      }
      // @ts-ignore
      const { data } = await addNoteService(formData)
      // console.log(res)
      if (data.success) {
        setFilesList([])
        reset()
      } else {
        setErrorMsg('Ошибка сохранения')
        setError(true)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleAddPhoto = (e: SyntheticEvent) => {
    const { files } = e.target as HTMLInputElement
    if (files) {
      setFilesList(Array.from(files))
    }
  }
  const handleRemoveFile = (name: string) => {
    const filteredList = filesList.filter((item) => item.name !== name)
    setFilesList([...filteredList])
  }
  const textFieldSize = () => {
    if (isMobile) {
      return 1
    }
    if (filesList.length) {
      return 3
    } else {
      return 5
    }
  }
  return (
    <Box
      pt={2}
      pl={1}
      pr={1}
      gap={1}
      display='flex'
      flexDirection='column'
    >
      <form
        style={{ display: 'flex', flexGrow: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          size='small'
          multiline
          fullWidth
          minRows={textFieldSize()}
          maxRows={5}
          error={error}
          helperText={errorMsg}
          {...register('message')}
        />
        {isLoading ? (
          <CircularProgress
            size={25}
            sx={{ mx: '9px' }}
          />
        ) : (
          <Box
            width={50}
            padding={'5px'}
          >
            <IconButton type={'submit'}>
              <ForwardToInboxIcon
                htmlColor='rgba(0, 114, 188, .7)'
                fontSize='medium'
              />
            </IconButton>

            <IconButton>
              <InputLabel
                htmlFor={'photosInput'}
                style={{ cursor: 'pointer', fontSize: 0 }}
              >
                <IconAttach />
              </InputLabel>
            </IconButton>
            <input
              id='photosInput'
              type='file'
              multiple
              hidden
              accept='.jpg, .jpeg, .png, .gif'
              {...register('file', { onChange: handleAddPhoto })}
            />
          </Box>
        )}
      </form>
      {!!filesList && (
        <Box
          gap={1}
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
        >
          {filesList.map(({ name }) => (
            <span
              className={styles.FilesListItem}
              key={name}
              onClick={() => handleRemoveFile(name)}
            >
              {cropName(name)} <IconDelete />
            </span>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default NotesForm
