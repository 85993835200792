import xls from '@/assets/img/xls.png'
import { useAppSelector } from '@/hooks'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import axios from 'axios'
import dayjs from 'dayjs'
import FileDownload from 'js-file-download'
import * as React from 'react'
import { useParams } from 'react-router-dom'

const DownloadAction = ({ disableDownload }: { disableDownload: boolean }) => {
  const { id } = useParams()
  const { fromDate, toDate } = useAppSelector(stateDeviceSelector)
  const [isLoading, setIsLoading] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = async (e: any) => {
    if (e.target.nodeName !== 'LI' && !e.target.id) {
      setAnchorEl(null)
      return
    }
    try {
      setIsLoading(true)
      setAnchorEl(null)
      const data = {
        deviceId: Number(id),
        dateFrom: dayjs(fromDate).toISOString(),
        dateTo: dayjs(toDate).toISOString(),
        formatTypeId: e.target.id === 'pdf' ? 2 : (1 as 1 | 2),
      }
      const res = await axios({
        url: `${import.meta.env.VITE_API_URL}/api/devices/reportIndividual`,
        method: 'POST',
        data: data,
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
        },
      })
      const date = dayjs(new Date()).format('YYYYMMDD')
      FileDownload(res.data, `reportSingle_${date}_${id}.${e.target.id}`)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disableDownload) {
      return null
    }
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: 450 }}
      >
        <MenuList>
          <MenuItem
            id='pdf'
            onClick={handleClose}
          >
            .pdf
          </MenuItem>
          <MenuItem
            id='xlsx'
            onClick={handleClose}
          >
            .xlsx
          </MenuItem>
        </MenuList>
      </Menu>
      {isLoading ? (
        <CircularProgress
          size={25}
          color='white'
        />
      ) : (
        <IconButton
          disabled={disableDownload}
          onClick={handleClick}
        >
          <img
            src={xls}
            alt='XLS'
            width={25}
            height={25}
          />
        </IconButton>
      )}
    </>
  )
}

export default DownloadAction
