import { PhotoItem } from '@/modules/device-module/types/deviceType'
import { CircularProgress } from '@mui/material'
import dayjs from 'dayjs'
import * as React from 'react'
import styles from './Swiper.module.scss'
import cn from 'classnames'
import Loader from '@/components/Atoms/Loader/Loader'
interface Props {
  item: PhotoItem
  activeSlide: boolean
}

export const PhotoBottom = ({ item, activeSlide }: Props) => {
  const [loader, setLoader] = React.useState(false)
  return (
    <div
      className={cn(styles.ThumbnailsItem, {
        [styles.ThumbnailsItemActive]: activeSlide,
      })}
    >
      {!loader && <Loader />}
      <img
        src={item.smallImg}
        alt={item.date}
        loading='lazy'
        onLoad={() => setLoader(true)}
      />
      <div className={styles.ThumbnailsItemText}>
        {dayjs(item.date).format('DD.MM.YY HH:mm')}
      </div>
    </div>
  )
}
