import { notifyError } from '@/components'
import { setIsLogin, setUser } from '@/modules/login/store/userLoginStore'
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL + '/api',
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('Accept', 'application/json')
    return headers
  },
})

export const baseQueryCustom: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  try {
    const result = (await baseQuery(args, api, extraOptions)) as any
    if (
      result?.error &&
      (result?.error?.originalStatus === 400 ||
        result?.error?.originalStatus === 500 ||
        result?.error?.originalStatus === 502)
    ) {
      return notifyError('Сервис временно недоступен. Попробуйте позже')
    }

    if (
      result?.error &&
      (result?.error?.status === 400 ||
        result?.error?.status === 500 ||
        result?.error?.status === 502)
    ) {
      return notifyError('Сервис временно недоступен. Попробуйте позже')
    }

    if (result?.error && result?.error?.status === 'FETCH_ERROR') {
      if (typeof window !== 'undefined') {
        // localStorage.setItem('pathname', window.location.pathname)
        // localStorage.clear()
        // api.dispatch(setUser(null))
        // api.dispatch(setIsLogin(false))
        // window.location.href = '/login'
      }
      //return notifyError('Что-то пошло не так. Попробуйте позже.')
    }

    if (result?.error && result?.error?.status === 401) {
      if (typeof window !== 'undefined') {
        localStorage.setItem('pathname', window.location.pathname)
        localStorage.clear()
        api.dispatch(setUser(null))
        api.dispatch(setIsLogin(false))
        window.location.href = '/login'
      }
    }
    if (!result?.meta?.request?.url.includes('/confirm-email-success')) {
      if (!result?.data?.success && Array.isArray(result?.data?.messages)) {
        result?.data?.messages.forEach((item: string) => {
          if (item?.includes('Not found')) {
            window.location.href = '/'
          }
          notifyError(item)
        })
      }
      if (
        !result?.data?.success &&
        (result?.data?.messages === null || !result?.data?.messages) &&
        result?.error?.status !== 'FETCH_ERROR'
      ) {
        notifyError('Сервис временно недоступен. Попробуйте позже')
      }
    }
    return result
  } catch (e) {
    console.log('e', e)
    return e
  }
}
