import { Box, Typography } from '@mui/material'

import React, { FC } from 'react'

interface Props {
  name: string
  suffix?: string
  value: number | string
  img: string
}

const Unit: FC<Props> = ({ name, suffix, value, img }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      minWidth={110}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        gap={1}
      >
        <img
          src={img}
          width={30}
          height={30}
          alt={name}
        />
        <Typography fontSize='0.8rem'>{name}</Typography>
      </Box>
      <Typography>
        {value} {suffix}
      </Typography>
    </Box>
  )
}

export default Unit
