import { FC } from 'react'
import info from '@/assets/img/errorBlue.png'
import { TypeIcon } from '@/components/Atoms/Icons/types'

const Icon: FC<TypeIcon> = ({ width = 30, height = 30 }) => {
  return (
    <img
      src={info}
      alt='Info'
      width={width}
      height={height}
    />
  )
}

export default Icon
