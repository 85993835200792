import IconModule from '@/components/Atoms/Icons/IconModule'
import { useActions, useAppSelector } from '@/hooks'
import * as React from 'react'
import FilterDropdown from '@/components/Organisms/FilterDropdown'
import { filterSystemSelector } from '@/store/filterSystemsSlise'

const FilterByModule = () => {
  const { setModulesStateFilter } = useActions()
  const { modulesFilterValues } = useAppSelector(filterSystemSelector)

  const handleChange = (arrId: number[]) => {
    setModulesStateFilter(arrId)
  }

  return (
    <>
      <FilterDropdown
        onChange={handleChange}
        list={modulesFilterValues}
      >
        <IconModule />
      </FilterDropdown>
    </>
  )
}

export default FilterByModule
