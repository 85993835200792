import { useActions, useAppSelector } from '@/hooks'
import { PhotoBottom } from './PhotoBottom'
import { useGetPhotosMutation } from '@/modules/device-module/services/deviceService'
import throttle from 'just-throttle'
import { photosSelector } from '@/modules/device-module/store/photoSliderSlice'
import PhotoBig from './PhotoBig'
import * as React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination, Thumbs, Virtual, Keyboard } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper/types'
import styles from './Swiper.module.scss'
import {
  stateDeviceSelectorFromDateEvents,
  stateDeviceSelectorToDate,
} from '@/modules/aqua-device-module/store/stateSlice'
import dayjs from 'dayjs'

const THUMBS_BREAKPOINTS = {
  1080: {
    slidesPerView: 6,
  },
  880: {
    slidesPerView: 5,
  },
  768: {
    slidesPerView: 4,
  },
  320: {
    slidesPerView: 2,
  },
}
// TODO keyPressHandler throttle
// const fn2 = throttle(() => console.log('hello'), 1500, { leading: true })
const ImageSliderSwiper = () => {
  const { id } = useParams()
  const fromDate = useAppSelector(stateDeviceSelectorFromDateEvents)
  const toDate = useAppSelector(stateDeviceSelectorToDate)
  const { addPhotoBefore, addPhotoAfter, setPhotos } = useActions()

  const photos = useAppSelector(photosSelector)
  const [getPhotos] = useGetPhotosMutation()
  const [activeSlide, setActiveSlide] = useState(0)
  const [swiperRefBottom, setSwiperRefBottom] = useState<SwiperType | null>(
    null
  )

  const slideLeft = () => {
    if (activeSlide !== 1) {
      setActiveSlide((prev) => prev - 1)
    } else {
      handlerReachLast('left')
    }
  }

  const slideRight = () => {
    if (activeSlide !== photos.length - 1) {
      setActiveSlide((prev) => prev + 1)
    } else {
      handlerReachLast('right')
    }
  }

  const keyPressHandler = (swiper: SwiperType, key: number | string) => {
    if (key === 37) {
      slideLeft()
    }
    if (key === 39) {
      slideRight()
    }
  }
  const handlerReachLast = (position: 'left' | 'right') => {
    let toLeft = true
    let lastPhotoDate = photos.at(-1)?.date ?? ''
    if (position === 'left') {
      lastPhotoDate = photos.at(0)?.date ?? ''
      toLeft = false
    }
    getPhotos({
      isNew: toLeft,
      deviceId: id ?? '0',
      lastPhotoDate,
      startDate: dayjs(fromDate).toJSON().slice(0, -5),
      endDate: dayjs(toDate).toJSON().slice(0, -5),
    })
      .unwrap()
      .then((data) => {
        if (data.length > 0) {
          if (position === 'right') {
            addPhotoAfter(data)
          } else {
            addPhotoBefore(data)
            swiperRefBottom?.slideTo(data.length - 1)
            setActiveSlide(() => data.length - 1)
          }
        }
      })
  }

  React.useEffect(() => {
    getPhotos({
      isNew: true,
      deviceId: id ?? '0',
      lastPhotoDate: undefined,
      startDate: dayjs(fromDate).toJSON().slice(0, -5),
      endDate: dayjs(toDate).toJSON().slice(0, -5),
    })
      .unwrap()
      .then((data) => {
        setPhotos(data)
        swiperRefBottom?.slideTo(data.length - 1)
        setActiveSlide(() => data.length - 1)
      })
  }, [fromDate, toDate])

  if (photos.length === 0 || !photos[activeSlide]) {
    return null
  }
  return (
    <div className={styles.MainSliderWrapper}>
      <PhotoBig
        activeSlide={activeSlide}
        photos={photos}
        swiperRefBottom={swiperRefBottom}
        slideLeft={slideLeft}
        slideRight={slideRight}
      />
      <div className={styles.ThumbnailsWrapper}>
        <Swiper
          onInit={(swiper) => {
            setSwiperRefBottom(swiper)
          }}
          breakpoints={THUMBS_BREAKPOINTS}
          centeredSlides={false}
          keyboard={true}
          speed={500}
          onKeyPress={keyPressHandler}
          initialSlide={photos.length - 1}
          onClick={(swiper) => {
            swiper.slideTo(swiper.activeIndex)
            setActiveSlide(swiper.clickedIndex)
          }}
          freeMode={true}
          allowTouchMove={true}
          observer
          observeParents
          onTouchEnd={(swiper) => {
            if (swiper.isEnd) handlerReachLast('right')
            if (swiper.isBeginning) handlerReachLast('left')
          }}
          modules={[Pagination, Thumbs, Virtual, Keyboard]}
        >
          {photos.map((item, index) => (
            <SwiperSlide
              key={item.id}
              virtualIndex={item.id}
            >
              <PhotoBottom
                item={item}
                activeSlide={activeSlide === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default ImageSliderSwiper
