import errorImg from '@/assets/img/error-red.png'
import errorYellow from '@/assets/img/icons-error.png'
import { useActions, useAppSelector } from '@/hooks'
import ConnectionStatus from '@/modules/device-module/components/table/ConnectionStatus'
import Light from '@/modules/device-module/components/table/Light'
import Turn from '@/modules/device-module/components/table/Turn'
import Voltage from '@/modules/device-module/components/table/Voltage'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { DeviceModule, Status } from '@/modules/device-module/types/deviceType'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import { COLORS } from '@/utils'
import ClearIcon from '@mui/icons-material/Clear'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Checkbox, IconButton, TextField } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import React, { FC, useEffect } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'

interface RowTableProps {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  id: number
  index: number
  isItemSelected: boolean
  item: DeviceModule
  handleClick: (event: React.MouseEvent<unknown>, id: number) => void
  isHVLamp: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
  setError2: React.Dispatch<React.SetStateAction<boolean>>
  error: boolean
  error2: boolean
}

const RowTable: FC<RowTableProps> = ({
  provided,
  snapshot,
  id,
  isHVLamp,
  isItemSelected,
  index,
  item,
  handleClick,
  setError,
  setError2,
  error,
  error2,
}) => {
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { isOnChange, device, selected } = useAppSelector(stateDeviceSelector)
  const {
    removeItemDevice,
    setDeviceModules,
    setDisabledSaveBtn,
    setAddDeleted,
  } = useActions()

  const [errorText, setErrorText] = React.useState('')
  const [errorText2, setErrorText2] = React.useState('')

  useEffect(() => {
    if (item.name.trim() === '') {
      setError(true)
      setDisabledSaveBtn(true)
      setErrorText('Имя не может быть пустым')
    } else {
      setError(false)
      setDisabledSaveBtn(false)
      setErrorText('')
    }
    // if(item.name.length > 30) {
    //   setError(true)
    //   setDisabledSaveBtn(true)
    //   setErrorText('Поле должно содержать не более 30 символов')
    // } else {
    //   setError(false)
    //   setDisabledSaveBtn(false)
    //   setErrorText('')
    // }
  }, [item.name])

  useEffect(() => {
    if (item.name?.length > 30) {
      setError2(true)
      setDisabledSaveBtn(true)
      setErrorText2('Поле должно содержать не более 30 символов')
    } else {
      setError2(false)
      setDisabledSaveBtn(false)
      setErrorText2('')
    }
  }, [item.name?.length])

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: number
  ) => {
    const updatedItems = device?.modules.map((item) => {
      if (item.id === itemId) {
        return { ...item, name: event.target.value }
      }
      return item
    })
    if (updatedItems) {
      setDeviceModules(updatedItems)
    }
  }

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{
        //left: -49,
        backgroundColor: snapshot.isDragging ? '#fff' : 'transparent',
        borderWidth: 3,
        borderColor: snapshot.isDragging
          ? 'rgba(0, 114, 188, .5)'
          : 'rgba(0, 0, 0, .05)',
        // transition: 'background-color 300ms linear',
        // '&::before': {
        //   content: '\'\'',
        //   position: 'absolute',
        //   width: '100%',
        //   height: '100%',
        //   zIndex: 1,
        //   border: selected.includes(id)
        //     ? `3px solid ${COLORS[index]}` : '3px solid transparent',
        // },
      }}
    >
      {isOnChange ? (
        <>
          <TableCell
            padding='checkbox'
            align='center'
            sx={{ paddingTop: 1, position: 'relative', zIndex: 2 }}
          >
            <span
              {...provided.dragHandleProps}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <DragIndicatorIcon />
            </span>
          </TableCell>
          <TableCell
            padding='checkbox'
            sx={{ width: 35, position: 'relative', zIndex: 2 }}
          >
            <IconButton
              size='small'
              onClick={() => {
                removeItemDevice(index)
                setAddDeleted(id)
              }}
            >
              <ClearIcon htmlColor='red' />
            </IconButton>
          </TableCell>
        </>
      ) : null}
      <TableCell padding='checkbox'>
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, id)}
          sx={{
            '&.Mui-checked': {
              color: COLORS[index],
            },
          }}
        />
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        padding='none'
        sx={{ position: 'relative', zIndex: 2 }}
      >
        {isOnChange ? (
          <Box px={1}>
            <TextField
              size='small'
              fullWidth
              value={item.name}
              error={error || error2}
              onChange={(event) => handleNameChange(event, item.id)}
              helperText={errorText || errorText2}
            />
          </Box>
        ) : (
          <Box>{item.name}</Box>
        )}
        <Box sx={{ display: { xss: 'block', lg: 'none' } }}>
          {currentUserType === UserTypes.superAdmin ? item.address : ''}
        </Box>
      </TableCell>
      <TableCell
        padding='none'
        align='left'
        sx={{
          display: { xss: 'none', lg: 'table-cell' },
          position: 'relative',
          zIndex: 2,
        }}
      >
        {currentUserType === UserTypes.superAdmin ? item.address : ''}
      </TableCell>
      <TableCell
        padding='none'
        align='center'
        sx={{
          width: { xss: 160, lg: 60 },
          display: { xss: 'table-cell', md: 'table-cell' },
          position: 'relative',
          zIndex: 2,
        }}
      >
        {item.state === Status.error ? (
          <Tooltip
            title={item?.errorMessage ?? 'Система неисправна'}
            placement='top'
          >
            <img
              src={errorImg}
              alt='Ошибка'
              width={30}
              height={30}
            />
          </Tooltip>
        ) : item.state === Status.warning ? (
          <Tooltip
            title={item?.errorMessage ?? 'Ошибки в работе системы'}
            placement='top'
          >
            <img
              src={errorYellow}
              alt='Ошибка'
              width={30}
              height={30}
            />
          </Tooltip>
        ) : null}
        <Box
          sx={{
            display: { xss: 'flex', lg: 'none' },
            justifyContent: 'center',
          }}
        >
          <ConnectionStatus
            connectionStatus={item.connectionStatus}
            lastPackageDate={item.lastPackageDate}
          />
        </Box>
      </TableCell>
      <TableCell
        padding='none'
        align='left'
        width={
          device?.modules.filter(
            (unit) => unit.connectionStatus === 3 || unit.connectionStatus === 2
          ).length === 0
            ? 105
            : 155
        }
        sx={{ display: { xss: 'none', lg: 'table-cell' }, pl: 1 }}
      >
        <ConnectionStatus
          connectionStatus={item.connectionStatus}
          lastPackageDate={item.lastPackageDate}
        />
      </TableCell>
      <TableCell
        padding='none'
        align='left'
        width={90}
        sx={{
          display: { xss: 'table-cell', md: isHVLamp ? 'none' : 'table-cell' },
          position: 'relative',
          zIndex: 2,
          pl: 1,
        }}
      >
        {isHVLamp ? null : <Voltage voltage={item.powerVoltage} />}
        <Box sx={{ display: { xss: 'block', lg: 'none' } }}>
          {device?.typeId === 1 ? (
            <Turn
              statusLeft={item.leftTurnActivitiStatus}
              statusRight={item.rightTurnActivitiStatus}
            />
          ) : (
            <Light
              status={item.activitiStatus}
              lastPowerOff={item.lastPowerOff}
              lastPowerOn={item.lastPowerOn}
            />
          )}
        </Box>
      </TableCell>
      <TableCell
        padding='none'
        align='right'
        width={80}
        sx={{ display: { xss: 'none', lg: 'table-cell' }, px: 2 }}
      >
        {device?.typeId === 1 ? (
          <Turn
            statusLeft={item.leftTurnActivitiStatus}
            statusRight={item.rightTurnActivitiStatus}
          />
        ) : (
          <Light
            status={item.activitiStatus}
            lastPowerOff={item.lastPowerOff}
            lastPowerOn={item.lastPowerOn}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default RowTable
