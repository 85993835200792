import React, { useEffect } from 'react'
import styles from '../DIT.module.scss'
import { Select, MenuItem, TextField } from '@mui/material'
import {
  getActiveProgram,
  getActiveSlide,
} from '@/components/Organisms/DIT/helpers'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'

const ANIMATION_LIST = ['Статичный', 'Мигание']
const SlideForm = () => {
  const programs = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const isDisabled = useAppSelector(
    ditSliceSelectors.stateDitActiveProgramDisabled
  )
  const activeSlideId = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeProgramIndex = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const activeProgram = getActiveProgram(activeProgramIndex, programs)
  const activeSlide = getActiveSlide(activeSlideId, activeProgram?.slides)
  const [animation, setAnimation] = React.useState(activeSlide.flicker)
  const [animationDuration, setAnimationDuration] = React.useState(
    activeSlide.showTime
  )
  const { ditEditSlideItem } = useActions()
  useEffect(() => {
    if (
      activeProgramIndex &&
      activeSlideId &&
      (activeSlide.showTime !== animationDuration ||
        activeSlide.flicker !== animation)
    ) {
      ditEditSlideItem({
        programId: activeProgramIndex,
        slideId: activeSlideId,
        showTime: animationDuration,
        flicker: animation,
      })
    }
  }, [animation, animationDuration])
  const handleAnimation = (e: any) => {
    setAnimation(e.target.value !== ANIMATION_LIST[0])
  }
  const handleAnimationDuration = (e: any) => {
    if (Number(e.target.value) >= 5 && Number(e.target.value) <= 999) {
      setAnimationDuration(Number(e.target.value))
    }
  }
  return (
    <div className={styles.SlideForm}>
      <div className={styles.SlideFormItem}>
        <label htmlFor='duration'>Длительность отображения</label>
        <TextField
          disabled={isDisabled}
          className={styles.SlideFormInput}
          color='primary'
          size='small'
          id='duration'
          type='number'
          margin='none'
          value={animationDuration}
          onChange={handleAnimationDuration}
        />
        <span> &nbsp;&nbsp;сек</span>
      </div>
      <div className={styles.SlideFormItem}>
        <label htmlFor='animation'>Анимация</label>
        <Select
          disabled={isDisabled}
          id='animation'
          className={styles.Select}
          color='primary'
          size='small'
          value={animation ? ANIMATION_LIST[1] : ANIMATION_LIST[0]}
          onChange={handleAnimation}
        >
          {ANIMATION_LIST.map((s) => (
            <MenuItem
              key={s}
              value={s}
            >
              {s}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default SlideForm
