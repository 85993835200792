import { useActions } from '@/hooks'
import {
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const Title = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })

  const { toggleFScreen, setSelectedTab } = useActions()

  return (
    <Box maxWidth={{ xss: '100%', md: '50%' }}>
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton
          onClick={() => {
            navigate(-1)
            toggleFScreen(false)
            setSelectedTab(0)
          }}
        >
          <KeyboardBackspaceIcon htmlColor='#fff' />
        </IconButton>
        <span style={{ color: 'rgba(255, 255, 255, .5)' }}>
          {data?.type || photosData?.type} {data?.name ?? photosData?.name}
        </span>
      </Stack>
      <Typography sx={{ color: '#fff', fontSize: { xss: 16, lg: 20 } }}>
        {data?.address ?? photosData?.address ?? '---'}
      </Typography>
    </Box>
  )
}

export default Title
