import React, { useEffect } from 'react'
import LayoutPage from '@/components/Organisms/LayoutPage'
import DIT from '@/components/Organisms/DIT'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useActions, useAppSelector } from '@/hooks'
// import { DATA } from '@/components/Organisms/DITConstructor/data'
import ErrorBoundary from '@/components/Organisms/ErrorBoundary'
import { useGetDitsQuery } from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'

const DITPage = () => {
  const { ditData } = useAppSelector(infoIconsSelector)
  const { ditSetDitSize } = useActions()

  useEffect(() => {
    if (ditData?.width && ditData?.height) {
      ditSetDitSize({ width: ditData?.width, height: ditData?.height })
    }
  }, [ditData])
  const { id } = useParams()
  useGetDitsQuery(Number(id))
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  return (
    <ErrorBoundary>
      <DIT data={programsState.programList} />
    </ErrorBoundary>
  )
}

export default DITPage
