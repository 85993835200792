import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import React from 'react'

const ConnectionStatus = ({
  connectionStatus,
  lastPackageDate,
}: {
  connectionStatus: number
  lastPackageDate: string
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Tooltip
        title={'Состояние подключения'}
        placement='top'
      >
        <img
          src={
            connectionStatus === 1
              ? cloudBlue
              : connectionStatus === 2
                ? cloudGray
                : connectionStatus === 3
                  ? cloudRed
                  : cloudRed
          }
          height={25}
          width={25}
          alt='Связь'
        />
      </Tooltip>
      <span>
        {connectionStatus === 1
          ? 'В сети'
          : connectionStatus === 2
            ? 'Не в сети'
            : connectionStatus === 3
              ? lastPackageDate
                ? dayjs(lastPackageDate).format('DD.MM.YYYY HH:mm')
                : 'Нет соединения'
              : '-'}
      </span>
    </Box>
  )
}

export default ConnectionStatus
