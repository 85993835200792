import React from 'react'
import type { Program } from '@/components/Organisms/DIT/types'
import styles from './DITTree.module.scss'
import { useActions, useAppSelector } from '@/hooks'
import IconCancel from '@/components/Atoms/Icons/IconCancel'
import IconSave from '@/components/Atoms/Icons/IconSave'
import IconEdit from '@/components/Atoms/Icons/IconEdit'
import IconModule from '@/components/Atoms/Icons/IconModule'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'
import DITProgram from '@/components/Organisms/DIT/DITTree/DITProgram'
import IconAddImage from '@/components/Atoms/Icons/IconAddImage'
import IconEditText from '@/components/Atoms/Icons/IconEditText'
import ModalSave from '@/components/Organisms/DIT/ModalSave/ModalSave'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { getActiveProgram } from '@/components/Organisms/DIT/helpers'
import {
  useGetDitsQuery,
  useUpdateDitsMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
type DITTreeProps = {
  programs: Program[]
}
const DITTree: React.FC<DITTreeProps> = ({ programs }) => {
  const { id } = useParams()
  const [editMode, setEditMode] = React.useState(false)
  const {
    ditAddProgram,
    ditAddSlide,
    ditAddSlideItem,
    ditSetUnsavedTreeChanges,
  } = useActions()
  const activeProgramIndex = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const hasChanges = useAppSelector(ditSliceSelectors.stateHasChanges)
  const activeSlide = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeProgram = getActiveProgram(activeProgramIndex, programs)
  const isDisabled = useAppSelector(
    ditSliceSelectors.stateDitActiveProgramDisabled
  )
  const { refetch } = useGetDitsQuery(Number(id))
  const [saveDIts] = useUpdateDitsMutation()
  const addProgramHandler = () => {
    ditAddProgram()
  }
  const addSlideHandler = () => {
    if (activeProgram?.slides?.length && activeProgram?.slides?.length === 5) {
      alert('Программа не может содержать более 5 слайдов')
      return null
    }
    if (activeProgramIndex) {
      ditAddSlide({
        programId: activeProgramIndex,
      })
    }
  }
  const addSlideItemHandler = (type: 'text' | 'image') => {
    if (activeProgramIndex && activeSlide) {
      ditAddSlideItem({
        programId: activeProgramIndex,
        slideId: activeSlide,
        type,
      })
    }
  }
  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const handleResetTree = () => {
    setEditMode(false)
    refetch()
    ditSetUnsavedTreeChanges(false)
  }
  const handleSaveTree = async () => {
    setEditMode(false)
    await saveDIts({
      deviceId: Number(id),
      programs: programsState.programList,
      deletedPrograms: programsState.deletedPrograms,
    })
    ditSetUnsavedTreeChanges(false)
  }
  return (
    <>
      <div className={styles.Wrapper}>
        <div style={{ pointerEvents: hasChanges ? 'none' : 'initial' }}>
          <div className={styles.Controls}>
            <div className={styles.ControlsLeft}>
              {!editMode && (
                <Tooltip
                  title={'Добавить программу'}
                  placement='top'
                >
                  <IconButton onClick={addProgramHandler}>
                    <IconAddDoc />
                  </IconButton>
                </Tooltip>
              )}

              {!editMode && !isDisabled && (
                <>
                  <Tooltip
                    title={'Добавить слайд'}
                    placement='top'
                  >
                    <IconButton
                      disabled={!activeProgramIndex}
                      onClick={addSlideHandler}
                    >
                      <IconModule />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={'Добавить текст'}
                    placement='top'
                  >
                    <IconButton
                      disabled={!activeSlide}
                      onClick={() => addSlideItemHandler('text')}
                    >
                      <IconEditText />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={'Добавить изображение'}
                    placement='top'
                  >
                    <IconButton
                      disabled={!activeSlide}
                      onClick={() => addSlideItemHandler('image')}
                    >
                      <IconAddImage />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <div className={styles.ControlsRight}>
              {editMode ? (
                <>
                  <IconButton onClick={handleResetTree}>
                    <IconCancel />
                  </IconButton>
                  <IconButton onClick={handleSaveTree}>
                    <IconSave />
                  </IconButton>
                </>
              ) : (
                <Tooltip
                  title={'Редактировать дерево'}
                  placement='top'
                >
                  <IconButton onClick={toggleEditMode}>
                    <IconEdit />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          {programs.map((program) => (
            <DITProgram
              key={program.index}
              program={program}
              editMode={editMode}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default DITTree
