import cloudBlue from '@/assets/img/cloud-blue.png'
import errorImg from '@/assets/img/error-red.png'
import errorYellow from '@/assets/img/icons-error.png'
import ok from '@/assets/img/ok-green.png'
import { useActions, useAppSelector } from '@/hooks'
import Unit from '@/modules/device-module/components/meteo/Unit'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import {
  DeviceModuleMeteo,
  Status,
} from '@/modules/device-module/types/deviceType'
import ClearIcon from '@mui/icons-material/Clear'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, IconButton, Radio, TextField, Typography } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import React, { FC, useEffect } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'

interface RowTableProps {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  id: number
  index: number
  item: DeviceModuleMeteo
  setError: React.Dispatch<React.SetStateAction<boolean>>
  setError2: React.Dispatch<React.SetStateAction<boolean>>
  error: boolean
  error2: boolean
}

const RowTable: FC<RowTableProps> = ({
  provided,
  snapshot,
  id,
  index,
  item,
  setError,
  setError2,
  error,
  error2,
}) => {
  const { isOnChange, device, selectedMeteo } =
    useAppSelector(stateDeviceSelector)
  const {
    removeItemDeviceMeteo,
    setDeviceModulesMeteo,
    setDisabledSaveBtn,
    setAddDeleted,
    setSelectedMeteo,
  } = useActions()

  const [errorText, setErrorText] = React.useState('')
  const [errorText2, setErrorText2] = React.useState('')

  useEffect(() => {
    if (item.name.trim() === '') {
      setError(true)
      setDisabledSaveBtn(true)
      setErrorText('Имя не может быть пустым')
    } else {
      setError(false)
      setDisabledSaveBtn(false)
      setErrorText('')
    }
  }, [item.name])

  useEffect(() => {
    if (item.name?.length > 30) {
      setError2(true)
      setDisabledSaveBtn(true)
      setErrorText2('Поле должно содержать не более 30 символов')
    } else {
      setError2(false)
      setDisabledSaveBtn(false)
      setErrorText2('')
    }
  }, [item.name?.length])

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: number
  ) => {
    const updatedItems = device?.modulesMeteo?.map((item) => {
      if (item.id === itemId) {
        return { ...item, name: event.target.value }
      }
      return item
    })
    if (updatedItems) {
      setDeviceModulesMeteo(updatedItems)
    }
  }

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{
        backgroundColor: snapshot.isDragging ? '#fff' : 'transparent',
        borderColor: snapshot.isDragging
          ? 'rgba(0, 114, 188, .5)'
          : 'rgba(0, 0, 0, .1)',
      }}
    >
      {isOnChange ? (
        <>
          <TableCell
            padding='checkbox'
            align='center'
            sx={{ paddingTop: 1 }}
          >
            <span
              {...provided.dragHandleProps}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <DragIndicatorIcon />
            </span>
          </TableCell>
          <TableCell
            padding='checkbox'
            sx={{ width: 35 }}
          >
            <IconButton
              size='small'
              onClick={() => {
                removeItemDeviceMeteo(index)
              }}
            >
              <ClearIcon htmlColor='red' />
            </IconButton>
          </TableCell>
        </>
      ) : null}
      <TableCell
        padding='checkbox'
        sx={{ width: 35 }}
      >
        <Radio
          checked={selectedMeteo === index + 1}
          onChange={() => {
            setSelectedMeteo(index + 1)
          }}
        />
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        padding='none'
      >
        <Box
          pb={2}
          pl={2}
        >
          {isOnChange ? (
            <Box
              py={1}
              pt={2}
              width={{ xss: '100%', lg: '50%' }}
            >
              <TextField
                size='small'
                fullWidth
                value={item.name}
                error={error || error2}
                onChange={(event) => handleNameChange(event, item.id)}
                helperText={errorText || errorText2}
              />
            </Box>
          ) : (
            <Typography
              color='primary'
              fontSize='1.2rem'
              py={1}
              pt={2}
            >
              {item.name}
            </Typography>
          )}
          <Box
            display='flex'
            gap={{ xss: 2, lg: 5 }}
            //flexWrap='wrap'
          >
            {item?.units?.map((unit, index) => (
              <Unit
                key={index}
                name={unit.name}
                suffix={unit.suffix}
                value={unit.value}
                img={unit.icon}
              />
            ))}
          </Box>
        </Box>
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        padding='none'
      />
      <TableCell
        component='th'
        scope='row'
        padding='none'
        align='center'
        sx={{ verticalAlign: 'middle' }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          pr={1}
          gap={1}
        >
          {item.info.state === Status.error ? (
            <Tooltip
              title={item?.errorMessage ?? 'Система неисправна'}
              placement='top'
            >
              <img
                src={errorImg}
                alt='Ошибка'
                width={30}
                height={30}
              />
            </Tooltip>
          ) : (
            item.info.state === Status.warning && (
              <Tooltip
                title={item?.errorMessage ?? 'Ошибки в работе системы'}
                placement='top'
              >
                <img
                  src={errorYellow}
                  alt='Ошибка'
                  width={30}
                  height={30}
                />
              </Tooltip>
            )
          )}
          <img
            src={cloudBlue}
            height={25}
            width={25}
            alt='Связь'
          />
          <Typography>
            {dayjs(item.info.date).format('DD.MM.YY HH:mm')}
          </Typography>
        </Box>
        <Typography>{item.info.message}</Typography>
      </TableCell>
    </TableRow>
  )
}

export default RowTable
