import iconDevicesState from '@/assets/img/icons/iconDevicesState.png'
import DevicesStatusContentPopover from '@/modules/devices-list/components/DevicesTable/DevicesStatusContentPopover'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import cn from '@/utils/cn'
import Box from '@mui/material/Box'
import { Popover } from 'antd'
import * as React from 'react'
import { FC } from 'react'
import { useAppSelector } from '@/hooks'

const s = cn('bottom-panel-location')

export type TotalSmartDetailsType = {
  totalSmartTurnCountErrors: number
  totalSmartTurnCountSuccess: number
  totalSmartLampCountErrors: number
  totalSmartLampCountSuccess: number
}

interface Props {
  totalDetails: TotalSmartDetailsType
}

const DevicesStatus: FC<Props> = ({ totalDetails }) => {
  if (!totalDetails) return null
  const { filter } = useAppSelector(filterSystemSelector)
  const totalSmartDevices = Object.values(totalDetails).reduce(
    (acc, item) => (acc += item),
    0
  )
  const showDevicesStatus =
    totalSmartDevices &&
    (filter.includes(0) || filter.includes(1) || filter.includes(2))
  if (!showDevicesStatus || !totalSmartDevices) return null
  return (
    <>
      <Popover
        className={s()}
        content={<DevicesStatusContentPopover totalDetails={totalDetails} />}
        trigger='hover'
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          gap={1}
          flexWrap='wrap'
        >
          <img
            width={30}
            height={30}
            src={iconDevicesState}
            alt='Статус устройств'
          />
          <span style={{ textAlign: 'center' }}>{totalSmartDevices} шт.</span>
        </Box>
      </Popover>
    </>
  )
}

export default DevicesStatus
