import _ from 'lodash'

export function getDevicesIds(number: number, array: number[]) {
  const arr = _.cloneDeep(array)
  const index = arr.indexOf(number)

  if (index !== -1) {
    arr.splice(index, 1)
  } else {
    arr.push(number)
  }

  return arr
}
