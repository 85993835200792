import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import * as React from 'react'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  list: { id: number; name: string }[]
  onChange: (ids: number[]) => void
}

const FilterDropdown = ({ list, children, onChange }: Props) => {
  const [selectedFilterList, setSelectedFilterList] = React.useState<number[]>(
    []
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e: any) => {
    if (
      e.target.nodeName === 'LI' ||
      e.target.nodeName === 'INPUT' ||
      e.target.ariaLabel === 'menu'
    ) {
      return
    }
    setAnchorEl(null)
  }

  const handleSelect = (id: number) => {
    if (!selectedFilterList.includes(id)) {
      setSelectedFilterList([...selectedFilterList, id])
    } else {
      const filteredList = selectedFilterList.filter((item) => item !== id)
      setSelectedFilterList([...filteredList])
    }
  }
  React.useEffect(() => {
    onChange(selectedFilterList)
  }, [selectedFilterList])
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: 450 }}
      >
        <MenuList>
          {list.map(({ id, name }) => {
            return (
              <MenuItem
                key={id}
                id={String(id)}
                onClick={handleClose}
                onClickCapture={() => handleSelect(id)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={selectedFilterList.includes(id)}
                    onChange={(e) => {
                      e.stopPropagation()
                      handleSelect(id)
                    }}
                  />
                </ListItemIcon>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={1}
                  aria-label='menu'
                >
                  {name}
                </Box>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
      <IconButton onClick={handleClick}>
        <Badge
          color={
            selectedFilterList?.length > 0 && !selectedFilterList.includes(0)
              ? 'green'
              : 'transparent'
          }
          variant='dot'
        >
          {children}
        </Badge>
      </IconButton>
    </>
  )
}

export default FilterDropdown
