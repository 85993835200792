import NotificationsIcon from '@mui/icons-material/Notifications'

const Icon = () => {
  return (
    <NotificationsIcon
      htmlColor='rgb(224 186 15)'
      fontSize='medium'
    />
  )
}

export default Icon
