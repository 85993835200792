import { Box, Slider, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

interface Props {
  columns: number
  setColumns?: Dispatch<SetStateAction<number>>
}

const ColumnSlider: FC<Props> = ({ setColumns, columns }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={300}
      px={3}
      pt={2}
    >
      <Typography>Количество колонок</Typography>
      <Slider
        min={1}
        max={6}
        step={1}
        value={columns}
        valueLabelDisplay='auto'
        onChange={(_, value) => {
          if (setColumns) {
            setColumns(value as number)
          }
        }}
        marks={[
          {
            value: 1,
            label: '1',
          },
          {
            value: 2,
            label: '2',
          },
          {
            value: 3,
            label: '3',
          },
          {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
          {
            value: 6,
            label: '6',
          },
        ]}
      />
    </Box>
  )
}

export default ColumnSlider
