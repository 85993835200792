import IconTextLeft from '@mui/icons-material/FormatAlignCenter'

const Icon = () => {
  return (
    <IconTextLeft
      htmlColor='rgba(0, 0, 0, 0.54)'
      fontSize='medium'
    />
  )
}

export default Icon
