import React from 'react'
import { Box } from '@mui/material'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useAppSelector } from '@/hooks'
import styles from './DIT.module.scss'
import DITTree from '@/components/Organisms/DIT/DITTree'
import DITConstructor from '@/components/Organisms/DIT/DITConstructor'
import type { Program } from './types'
import { LoadingButton } from '@mui/lab'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'
import { getActiveProgram, getActiveSlide } from './helpers'
import { useSetActiveDitMutation } from '@/components/Organisms/DIT/service'
import ModalSave from '@/components/Organisms/DIT/ModalSave/ModalSave'
import { useParams } from 'react-router-dom'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { LoaderFullScreen } from '@/ui'
import Slide from '@/components/Organisms/DIT/Slide/Slide'
type Props = {
  data: Program[]
}
const DIT: React.FC<Props> = ({ data }) => {
  const { id } = useParams()
  const [modalSetDit, setModalSetDit] = React.useState(false)
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const programs = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const activeSlideId = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const activeProgramIndex = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const activeProgram = getActiveProgram(activeProgramIndex, programs)
  const activeSlide = getActiveSlide(activeSlideId, activeProgram?.slides)
  const [setActiveDit, { isLoading: setActiveIsLoading }] =
    useSetActiveDitMutation()
  const [showModal, setShowModal] = React.useState(false)

  const handleSaveChanges = () => {
    setShowModal(true)
  }
  const handleActiveProgram = () => {
    setModalSetDit(true)
  }
  const approvedSendDit = async () => {
    if (activeProgramIndex && activeProgram?.id) {
      await setActiveDit({
        deviceId: Number(id),
        programId: activeProgram?.id,
      })
    }
    setModalSetDit(false)
  }
  const isProgramSelected = activeProgram && !activeSlideId && !activeSlideItem
  const activeProgramSlides = activeProgram?.slides?.map((slide) => slide) || []
  return (
    <div className={styles.Wrapper}>
      <LoaderFullScreen open={setActiveIsLoading} />
      <ModalSave
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <DialogChanges
        open={modalSetDit}
        handleAgree={approvedSendDit}
        handleClose={() => setModalSetDit(false)}
        handleDisAgree={() => setModalSetDit(false)}
        message={'Отправить программу на ДИТ'}
        btnSaveTitle={'Да'}
        btnCancelTitle={'Нет'}
      />
      <div className={styles.Left}>
        {programsState.hasChanges && (
          <div
            className={styles.LeftDisabled}
            onClick={handleSaveChanges}
          />
        )}
        <DITTree programs={data} />
      </div>
      <div className={styles.Right}>
        {programsState.treeHasChanges && (
          <div className={styles.RightDisabled} />
        )}

        {isProgramSelected &&
          activeProgramSlides.map((slide) => (
            <p
              key={slide.id}
              className={styles.Preview}
            >
              <span>
                {slide.flicker
                  ? `Мигание: ${slide.showTime} сек`
                  : `Статичный ${slide.showTime} сек`}
              </span>
              <img
                src={slide.preview}
                loading={'lazy'}
                alt=''
              />
            </p>
          ))}
        {!activeProgram && !programs.length && (
          <div className={styles.NoPrograms}>
            Список программ пуст. <br />
            Создайте программу
            <div>
              <IconAddDoc />
            </div>
          </div>
        )}
        <Slide activeProgram={activeProgram} />

        {activeSlideItem && (
          <DITConstructor
            width={programsState.pageData.width}
            height={programsState.pageData.height}
          />
        )}
        <br />
        {activeSlide && (
          <img
            src={activeSlide.preview}
            loading={'lazy'}
            alt=''
          />
        )}
        <br />
        <br />
        <Box
          display='flex'
          justifyContent='end'
          gap={2}
        >
          {!!activeProgram?.slides.length &&
            !activeProgram?.active &&
            isProgramSelected && (
              <LoadingButton
                disabled={setActiveIsLoading || activeProgram.active}
                variant='contained'
                autoFocus
                loading={false}
                onClick={handleActiveProgram}
              >
                {setActiveIsLoading ? 'Загрузка' : 'Отправить'}
              </LoadingButton>
            )}
        </Box>
      </div>
    </div>
  )
}

export default DIT
