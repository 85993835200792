import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog } from '@/components'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
type Props = {
  deviceId: string
}
const DeleteDeviceButton: FC<Props> = ({ deviceId }) => {
  const navigate = useNavigate()
  const [deleteDialog, setDeleteDialog] = useState(false)
  const handleDelete = async () => {
    try {
      await axios({
        url: `${import.meta.env.VITE_API_URL}/api/devices/deleteDevice`,
        method: 'POST',
        data: { deviceId: Number(deviceId) },
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
        },
      })
      setDeleteDialog(false)
      navigate('/devices')
    } catch (e) {
      alert('Ошибка')
      setDeleteDialog(false)
      console.error(e)
    }
  }
  return (
    <>
      <Dialog
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        handleAgree={handleDelete}
        message='Вы действительно желаете удалить удалить ?'
      />
      <LoadingButton
        loading={false}
        onClick={() => setDeleteDialog(true)}
      >
        <span>Удалить</span>
      </LoadingButton>
    </>
  )
}

export default DeleteDeviceButton
