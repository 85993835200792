import leftRed from '@/assets/img/left-red.png'
import rightRed from '@/assets/img/right-red.png'
import { TurnStatus } from '@/modules/device-module/types/deviceType'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

const Turn = ({
  statusLeft,
  statusRight,
}: {
  statusLeft: TurnStatus
  statusRight: TurnStatus
}) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      gap={1}
    >
      <Tooltip
        title={'Дорожный знак'}
        placement='top'
      >
        <img
          src={rightRed}
          alt='Поворот'
          width={30}
          height={30}
          style={{
            opacity:
              statusRight === TurnStatus.noActive
                ? 0
                : statusRight === TurnStatus.off
                  ? 0.2
                  : 1,
            display: statusRight === TurnStatus.noActive ? 'none' : 'block',
          }}
        />
      </Tooltip>
      <Tooltip
        title={'Дорожный знак'}
        placement='top'
      >
        <img
          src={leftRed}
          alt='Поворот'
          width={30}
          height={30}
          style={{
            opacity:
              statusLeft === TurnStatus.noActive
                ? 0
                : statusLeft === TurnStatus.off
                  ? 0.2
                  : 1,
            display: statusLeft === TurnStatus.noActive ? 'none' : 'block',
          }}
        />
      </Tooltip>
    </Box>
  )
}

export default Turn
