import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import { Box } from '@mui/material'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import OrganizationData from '@/modules/organization/components/OrganizationData/OrganizationData'
import Units from '@/modules/organization/components/Units/Units'
import { useParams } from 'react-router-dom'
import { useChangeSet } from '@/utils/useChangeSet'

interface IOrganizationTabs {
  setLoader: Dispatch<SetStateAction<boolean>>
}

const OrganizationTabs: FC<IOrganizationTabs> = ({ setLoader }) => {
  const { id } = useParams()

  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (id === 'new') {
      setValue(() => '1')
    } else {
      setValue(newValue)
    }
  }

  useChangeSet(() => {
    setValue(() => '1')
  }, [id])

  return (
    <Box
      px={{ xl: 1 }}
      sx={{ width: '100%', typography: 'body1' }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab
              label='Данные'
              value='1'
            />
            <Tab
              label='Доступные системы'
              value='2'
            />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <OrganizationData setLoader={setLoader} />
        </TabPanel>
        <TabPanel value='2'>
          <Units setLoader={setLoader} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default OrganizationTabs
