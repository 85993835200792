import styles from './style.module.scss'
import { CircularProgress } from '@mui/material'
const Loader = () => {
  return (
    <div className={styles.Loader}>
      <CircularProgress size={20} />
    </div>
  )
}

export default Loader
