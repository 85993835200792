import { notifyError } from '@/components'
import { useActions } from '@/hooks'
import cn from '@/utils/cn'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import AvatarEditor from 'react-avatar-editor'

const s = cn('avatar-dialog')

interface IDialog {
  open: boolean
  handleClose: () => void
  zoom: number
  setZoom: Dispatch<SetStateAction<number>>
  img: string | File
  setImg: Dispatch<SetStateAction<string | File>>
  position: { x: number; y: number }
  setPosition: Dispatch<SetStateAction<{ x: number; y: number }>>
  editorImg: AvatarEditor | null
  setEditorImg: Dispatch<SetStateAction<AvatarEditor | null>>
  setCanvas: Dispatch<SetStateAction<string | undefined>>
  resetAvatarEditor: () => void
  canvas: string | undefined
}

const AvatarDialog: FC<IDialog> = ({
  open,
  handleClose,
  img,
  setImg,
  setEditorImg,
  editorImg,
  setZoom,
  zoom,
  setCanvas,
  setPosition,
  position,
  resetAvatarEditor,
  canvas,
}) => {
  const { setIsChangeUser } = useActions()
  const setEditorRef = (e: AvatarEditor) => setEditorImg(e)
  const onLoadImg = (e: any) => {
    if (e && e.target && e.target.files && e.target.files[0].length === 0) {
      return
    }
    if (!e || !e.target || !e.target.files || !e.target.files[0]) {
      return notifyError('Что-то пошло не так, попробуйте другой файл.')
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size / 1000000 > 5) {
        return notifyError(
          'Выбранный файл слишком большой. Пожалуйста, выберете файл менее 5 Мб.'
        )
      } else {
        setImg(e.target.files[0])
        setIsChangeUser(true)
      }
    }
  }

  const onSubmit = () => {
    try {
      if (!img) {
        return notifyError('Файл не выбран')
      }
      const file = editorImg?.getImageScaledToCanvas().toDataURL()
      setCanvas(file)
      handleClose()
      setIsChangeUser(true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={s()}
    >
      <DialogTitle>Выберите фото</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AvatarEditor
          ref={setEditorRef}
          style={{ width: 300, height: 300 }}
          image={img}
          width={300}
          height={300}
          borderRadius={150}
          position={position}
          onPositionChange={(pos) => {
            setPosition(pos)
          }}
          border={50}
          color={[77, 73, 73, 0.8]}
          scale={zoom}
        />
        <Slider
          value={zoom}
          min={0.01}
          max={10}
          step={0.000001}
          disabled={false}
          onChange={(event, newValue) => {
            setZoom(newValue as number)
          }}
        />
        <Button
          variant='contained'
          component='label'
          disabled={false}
          startIcon={<CloudUploadOutlinedIcon />}
        >
          Загрузить фото
          <input
            id='avatar'
            type='file'
            accept='.jpg, .jpeg, .png, .gif'
            hidden
            onChange={onLoadImg}
          />
        </Button>
      </DialogContent>
      <DialogActions>
        {img ? (
          <Button
            onClick={resetAvatarEditor}
            variant='outlined'
            color='error'
          >
            Удалить фото
          </Button>
        ) : null}
        <Button
          onClick={handleClose}
          variant='outlined'
          color='error'
        >
          Закрыть
        </Button>
        <LoadingButton
          variant='outlined'
          autoFocus
          loading={false}
          loadingPosition='start'
          onClick={onSubmit}
          startIcon={<SaveIcon />}
        >
          Сохранить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AvatarDialog
