import lightNoLoad from '@/assets/img/light-no-load.png'
import lightOff from '@/assets/img/light-off.png'
import lightOn from '@/assets/img/light-on.png'
import { ActivityStatus } from '@/modules/device-module/types/deviceType'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import React from 'react'

const Light = ({
  status,
  lastPowerOff,
  lastPowerOn,
}: {
  status: ActivityStatus
  lastPowerOff: string | null
  lastPowerOn: string | null
}) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      gap={1}
    >
      <Tooltip
        title={'Время последнего включения'}
        placement='top'
      >
        <img
          src={
            status === ActivityStatus.noLoad
              ? lightNoLoad
              : status === ActivityStatus.noActive ||
                  status === ActivityStatus.off
                ? lightOff
                : lightOn
          }
          alt='Светильник'
          width={30}
          height={30}
          style={{ opacity: status === ActivityStatus.noActive ? 0.2 : 1 }}
        />
      </Tooltip>
      <Box
        display='flex'
        flexDirection='column'
        fontSize={10}
        width={26}
      >
        <span>{lastPowerOn ? dayjs(lastPowerOn).format('HH:mm') : '-'}</span>
        <span>{lastPowerOff ? dayjs(lastPowerOff).format('HH:mm') : '-'}</span>
      </Box>
    </Box>
  )
}

export default Light
