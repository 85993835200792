import DownIcon from '@mui/icons-material/KeyboardArrowDown'

const Icon = () => {
  return (
    <DownIcon
      htmlColor='rgba(0, 0, 0, 0.54)'
      fontSize='medium'
    />
  )
}

export default Icon
