import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { Box, Button, Radio, TableBody, Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useActions, useAppSelector } from '@/hooks'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import _ from 'lodash'
import { Dialog, notifySuccess } from '@/components'
import { useParams } from 'react-router-dom'
import {
  useGetDeviceDetailQuery,
  useUpdateMeteoMutation,
} from '@/modules/device-module/services/deviceService'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CHART_HEIGHT } from '@/constants'
import RowTable from '@/modules/device-module/components/meteo/RowTable'
import { MeteoSave } from '@/modules/device-module/types/deviceType'

interface Props {
  setLoading: any
}

const TableDevice: FC<Props> = ({ setLoading }) => {
  const { id } = useParams()

  const { refetch } = useGetDeviceDetailQuery(Number(id))

  const {
    isOnChange,
    device,
    selectedMeteo,
    disabledSaveBtn,
    selectedFilters,
  } = useAppSelector(stateDeviceSelector)

  const { setDevice, setSelectedMeteo, setClearDeleted, toggleOnChangeDevice } =
    useActions()

  const [updateDeviceService] = useUpdateMeteoMutation()

  const [openSaveDialog, setOpenSaveDialog] = useState(false)

  const [error, setError] = React.useState(false)
  const [error2, setError2] = React.useState(false)

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const updatedItems = _.cloneDeep(device)
    if (updatedItems && updatedItems.modulesMeteo) {
      const [reorderedItem] = updatedItems.modulesMeteo.splice(
        result.source.index,
        1
      )
      updatedItems.modulesMeteo.splice(
        result.destination.index,
        0,
        reorderedItem
      )
      updatedItems.modulesMeteo.forEach((item, index) => {
        item.displaySequence = index + 1
      })
      setDevice(updatedItems)
    }
  }

  const onSubmit = async () => {
    setOpenSaveDialog(false)
    setLoading(true)
    try {
      const saveModules = device?.modulesMeteo?.map((item) => {
        return {
          moduleId: item.moduleId,
          type: item.type,
          name: item.name,
          displaySequence: item.displaySequence,
          deleted: item.deleted,
        }
      })
      const data: MeteoSave = {
        deviceId: Number(id),
        modules: saveModules ?? [],
      }
      const res = await updateDeviceService(data).unwrap()
      if (res.success) {
        notifySuccess('Изменения сохранены')
        toggleOnChangeDevice()
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Dialog
          message='Внесенные изменения будут сохранены. Продолжить?'
          open={openSaveDialog}
          handleClose={() => setOpenSaveDialog(false)}
          handleAgree={onSubmit}
        />
        <TableContainer
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxHeight: {
              xss:
                document.documentElement.clientHeight -
                CHART_HEIGHT -
                (isOnChange ? 190 : 150),
              lg:
                document.documentElement.clientHeight -
                CHART_HEIGHT -
                (isOnChange ? 300 : 230),
            },
            backgroundColor: '#fff',
            maxWidth: '100vw',
          }}
        >
          <Table>
            <Droppable droppableId='table'>
              {(provided) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    transition: 'background-color 300ms linear',
                  }}
                >
                  <TableRow
                    sx={{
                      border: '2px solid rgba(0, 0, 0, .05)',
                    }}
                  >
                    {isOnChange ? (
                      <>
                        <TableCell
                          component='th'
                          scope='row'
                          padding='none'
                        />
                        <TableCell
                          component='th'
                          scope='row'
                          padding='none'
                        />
                      </>
                    ) : null}
                    <TableCell padding='checkbox'>
                      <Radio
                        checked={selectedMeteo === 0}
                        onChange={() => {
                          setSelectedMeteo(0)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component='th'
                      scope='row'
                      padding='none'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={5}
                      >
                        <Typography
                          color='primary'
                          fontSize='1.2rem'
                        >
                          КУМ
                        </Typography>
                        <Typography>{device?.mac}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {(device?.modulesMeteo && !selectedFilters.includes(0)
                    ? device?.modulesMeteo.filter((item) =>
                        selectedFilters.includes(item.info.state)
                      )
                    : device?.modulesMeteo
                  )
                    ?.filter((item) => !item.deleted)
                    .sort((a, b) => a.displaySequence - b.displaySequence)
                    .map((item, index) => (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <RowTable
                            provided={provided}
                            snapshot={snapshot}
                            id={item.id}
                            index={index}
                            item={item}
                            setError={setError}
                            setError2={setError2}
                            error={error}
                            error2={error2}
                          />
                        )}
                      </Draggable>
                    ))}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
      <Box
        display='flex'
        justifyContent='flex-end'
        gap={2}
        p={{ xss: isOnChange ? 1 : 0.5, lg: isOnChange ? 2 : 0.5 }}
      >
        {isOnChange ? (
          <>
            <Button
              variant='contained'
              disabled={disabledSaveBtn}
              onClick={() => {
                if (error || error2) {
                  return
                }
                setOpenSaveDialog(true)
              }}
            >
              Сохранить
            </Button>
            <Button
              onClick={() => {
                refetch()
                setClearDeleted()
                toggleOnChangeDevice()
              }}
            >
              Отменить
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default TableDevice
