import { useActions } from '@/hooks'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

interface Props {
  index: number
  selected: number
  setSelected: Dispatch<SetStateAction<number>>
  setOpenDeleteSectionDialog: Dispatch<SetStateAction<boolean>>
  name: string
}

const SectionItem = ({
  index,
  setSelected,
  selected,
  setOpenDeleteSectionDialog,
  name,
}: Props) => {
  const [isEdit, setIsEdit] = useState(false)

  const { editNameFavorite } = useActions()

  const getSecondaryAction = (index: number) => {
    if (selected === index) {
      return (
        <Box>
          {/*{*/}
          {/*  isEdit ?*/}
          {/*    <IconButton*/}
          {/*      onClick={() => setIsEdit(false)}*/}
          {/*    >*/}
          {/*      <DoneIcon htmlColor='green' />*/}
          {/*    </IconButton> :*/}
          {/*    <IconButton*/}
          {/*      onClick={() => setIsEdit(true)}*/}
          {/*    >*/}
          {/*      <EditIcon htmlColor='gray' />*/}
          {/*    </IconButton>*/}
          {/*}*/}
          <IconButton
            edge='end'
            onClick={() => setOpenDeleteSectionDialog(true)}
          >
            <ClearIcon htmlColor='red' />
          </IconButton>
        </Box>
      )
    }
    return <div />
  }

  return (
    <Draggable
      draggableId={index.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            backgroundColor: snapshot.isDragging ? '#fff' : 'transparent',
            border: snapshot.isDragging
              ? '1px solid rgba(0, 114, 188, .5)'
              : 'unset',
            transition: 'background-color 300ms linear',
          }}
          key={index}
          secondaryAction={getSecondaryAction(index)}
        >
          <ListItemIcon sx={{ minWidth: 23 }}>
            <span
              {...provided.dragHandleProps}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <DragIndicatorIcon />
            </span>
          </ListItemIcon>
          <ListItemButton
            selected={selected === index}
            onClick={() => setSelected(index)}
          >
            {isEdit ? (
              <TextField
                //variant='standard'
                sx={{ padding: '4.5px 9px' }}
                defaultValue={name}
                value={name}
                onChange={(e) => {
                  editNameFavorite({ name: e.target.value, index })
                }}
              />
            ) : (
              <ListItemText primary={name} />
            )}
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  )
}

export default SectionItem
