import React, { FC } from 'react'
import styles from './NotesImages.module.scss'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/plugins/captions.css'
import { useMediaQuery } from '@mui/material'
type Props = {
  noteId: number
  imagesIds: number[]
  date: string
}

type CustomImage = {
  thumbnail: string
  src: string
  alt?: string
  description?: string
  title?: string
}

const API_PATH = '/api/devices/getNoteImage'

const getImages = ({ noteId, imagesIds, date }: Props) => {
  const res: CustomImage[] = []
  const formattedDate = new Date(date)
  imagesIds.forEach((id, index) => {
    res.push({
      src: `${import.meta.env.VITE_API_URL}${API_PATH}?noteId=${noteId}&id=${id}&small=false`,
      thumbnail: `${import.meta.env.VITE_API_URL}${API_PATH}?noteId=${noteId}&id=${id}&small=true`,
      title: `Изображение ${index + 1} из ${imagesIds.length} - ${formattedDate.toLocaleDateString()}`,
    })
  })
  return res
}

const NotesImages: FC<Props> = ({ noteId, imagesIds, date }) => {
  if (!noteId || !imagesIds.length) return null
  const images: CustomImage[] = getImages({ noteId, imagesIds, date })
  const [index, setIndex] = React.useState(0)
  const [open, setOpen] = React.useState(false)

  const handleOpen = (index: number) => {
    setIndex(index)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <div className={styles.Wrapper}>
      {open && (
        <Lightbox
          open={open}
          close={handleClose}
          index={index}
          slides={images}
          plugins={[Captions]}
          className={isMobile ? styles.GalleryMobile : styles.Gallery}
        />
      )}
      {images.map((image, index) => (
        <img
          onClick={() => handleOpen(index)}
          className={styles.Image}
          key={image.thumbnail}
          src={image.thumbnail}
        />
      ))}
    </div>
  )
}

export default NotesImages
