import { notifyError } from '@/components'

export const urlBase64ToUint8Array = (base64String: any) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

const registerServiceWorker = async () => {
  try {
    const res = await navigator.serviceWorker.register(
      `/${import.meta.env.VITE_APP_NAME}/service-worker.js`
    )
    console.log(res)
    console.log('Service worker registration successful!')
    return res
  } catch (e) {
    console.warn('Service worker registration failed ', e)
  }
}

export const checkPermission = () => {
  if (!('serviceWorker' in navigator && 'PushManager' in window)) {
    return notifyError(
      'Не поддерживаются уведомления. Пожалуйста, используйте браузер Chrome.'
    )
  } else {
    registerServiceWorker()
  }
}

const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission()
    if (permission !== 'granted') {
      return notifyError('Не удалось получить разрешение на уведомления.')
    }
  } catch (e) {
    console.log('Service worker request permission failed ', e)
  }
}

const main = async () => {
  checkPermission()
}

main()

export const saveSubscription = async (subscription: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/events/addWebPushSubscription`,
    {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Accept: '*/*',
      },
      body: subscription,
    }
  )

  return response.json()
}

export const deleteSubscription = async (subscriptionEndpoint: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/events/deleteWebPushSubscription`,
    {
      method: 'post',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ endpoint: subscriptionEndpoint }),
    }
  )

  return response.json()
}

export const getPush = async (text: string) => {
  await fetch('http://localhost:7000/push', {
    method: 'post',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ text }),
  })
}

export const VAPID_PUBLIC_KEY =
  'BKKes3Z6gWrUp5PUbjOJ9L0ilRmIbRibyNHs4OtRuPmABRK1sLeHPro-P0-LB3A0vUfPkUjtfvGbzr3hGQ2OGdc'
