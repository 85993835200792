import cn from '@/utils/cn'
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  useMediaQuery,
} from '@mui/material'
import React, { FC } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'

const s = cn('select-unit')

type Item = {
  name: string
  id: number
}

type ISelect = {
  items: Item[] | undefined
}

type SelectPropsUser = ISelect &
  SelectProps &
  FormControlProps &
  UseControllerProps<any>

const UnitSelect: FC<SelectPropsUser> = ({
  label,
  control,
  name,
  items = [],
  disabled,
  value,
}) => {
  const is4K = useMediaQuery('(min-width:2560px)')

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
  })
  return (
    <FormControl
      variant='filled'
      fullWidth
      size={is4K ? undefined : 'small'}
      margin='none'
      className={s()}
      disabled={disabled}
      // defaultValue={1}
    >
      <InputLabel
        sx={{
          color: errors[name]?.message ? '#d32f2f !important' : '',
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...field}
        error={!!errors[name]}
        disableUnderline
      >
        {items?.map((item, index) => (
          <MenuItem
            key={item?.id}
            value={item?.id}
          >
            {item?.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ color: '#d32f2f' }}>
        {errors[name]?.message as string}
      </FormHelperText>
    </FormControl>
  )
}

export default UnitSelect
