import React from 'react'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'

const format = 'HH'

const App: React.FC = () => {
  const startTime = dayjs('01', 'HH')
  const endTime = dayjs('24', 'HH')

  return (
    <div>
      <TimePicker.RangePicker
        defaultValue={[startTime, endTime]}
        format={format}
        size={'large'}
      />
    </div>
  )
}

export default App
