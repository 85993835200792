import star from '@/assets/img/icons8-star-filled-40.png'
import starFilled from '@/assets/img/star-filled.png'
import { notifySuccess } from '@/components'
import {
  useAddToFavoritesMutation,
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import { AddToFavorites } from '@/modules/device-module/types/deviceType'
import {
  useGetFavoritesSmallQuery,
  useGetFavoritesWithDevicesQuery,
} from '@/modules/favorites/services/favoritesService'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

const FavoritesMenu = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })

  const { data: favoritesData } = useGetFavoritesSmallQuery()
  const { data: favoritesWithDevices } = useGetFavoritesWithDevicesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return {
          data: data?.data.map((item) => ({
            ...item,
            devices: item.devices.map((device) => device.id),
          })),
        }
      },
    }
  )
  const [addToFavorites, { isLoading }] = useAddToFavoritesMutation()

  const [showMenu, setShowMenu] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = async (devicesIds?: number[], msg?: string) => {
    setAnchorEl(null)
    if (devicesIds) {
      try {
        const data: AddToFavorites = {
          deviceId: Number(id),
          favoriteIds: devicesIds,
        }
        const res = await addToFavorites(data).unwrap()
        if (res.success) {
          notifySuccess(msg ?? 'Устройство добавлено в избранное')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  const arr: number[] = []
  let msg = 'Устройство добавлено в избранное'

  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        sx={{ maxHeight: 450 }}
      >
        {favoritesData?.data?.length && favoritesData?.data?.length > 0 ? (
          <div>
            <span style={{ paddingLeft: 5, color: '#0072BC' }}>Избранное</span>
            <Divider />
            <MenuList>
              {favoritesWithDevices?.map((item, index) => {
                if (item.devices.includes(Number(id))) {
                  arr.push(item?.id ?? 0)
                }
                return (
                  <MenuItem
                    key={index}
                    id={item?.id?.toString()}
                    onClick={() => {
                      if (item.id) {
                        if (item.devices.includes(Number(id))) {
                          const index = arr.indexOf(item.id)
                          arr.splice(index, 1)
                          msg = 'Устройство удалено из избранного'
                        } else {
                          arr.push(item.id)
                        }
                        handleClose(arr, msg)
                      }
                    }}
                  >
                    <ListItemIcon>
                      {item.devices.includes(Number(id)) ? (
                        <StarIcon
                          fontSize='small'
                          htmlColor='#e4dc42'
                        />
                      ) : (
                        <StarBorderIcon fontSize='small' />
                      )}
                    </ListItemIcon>
                    {item.name}
                  </MenuItem>
                )
              })}
            </MenuList>
          </div>
        ) : (
          <div>
            <span style={{ paddingLeft: 5, color: '#0072BC' }}>
              Нет разделов
            </span>
            <Divider />
            <MenuItem
              id='1'
              onClick={() => handleClose()}
            >
              <Link
                to='/favorites'
                style={{ color: '#000' }}
              >
                Создать раздел?
              </Link>
            </MenuItem>
          </div>
        )}
      </Menu>
      {isLoading ? (
        <CircularProgress size={35} />
      ) : (
        <IconButton
          onClick={handleClick}
          size='medium'
        >
          <Tooltip
            title={'Добавить в избранное'}
            placement='top'
          >
            <img
              width={20}
              height={20}
              src={data?.favorite || photosData?.favorite ? starFilled : star}
              alt='Избранное'
            />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  )
}

export default FavoritesMenu
