import React from 'react'
import styles from '../DIT.module.scss'
import type { Program } from '@/components/Organisms/DIT/types'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import SlideForm from '@/components/Organisms/DIT/SlideForm/SlideForm'
import IconModule from '@/components/Atoms/Icons/IconModule'
import SaveButtons from '@/components/Organisms/DIT/SaveButtons/SaveButtons'
import IconEditText from '@/components/Atoms/Icons/IconEditText'
import IconAddImage from '@/components/Atoms/Icons/IconAddImage'
import { getActiveSlide } from '@/components/Organisms/DIT/helpers'

interface Props {
  activeProgram: Program | undefined
}
const Slide = ({ activeProgram }: Props) => {
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const activeSlideId = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  if (!activeProgram || activeSlideItem) return null
  const hasItems = activeProgram.slides.find((slide) => slide.items.length > 0)
  return (
    <>
      {!activeProgram.slides.length ? (
        <div className={styles.NoPrograms}>
          Создайте слайд
          <div>
            <IconModule />
          </div>
        </div>
      ) : (
        <>
          {activeSlideId && <SlideForm />}
          {!hasItems && (
            <div className={styles.NoPrograms}>
              Добавьте элементы
              <div>
                <IconEditText />
                <IconAddImage />
              </div>
            </div>
          )}
        </>
      )}
      {activeSlideId && <SaveButtons />}
    </>
  )
}

export default Slide
