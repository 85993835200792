import { useActions, useAppSelector } from '@/hooks'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import * as React from 'react'
import { filterSystemSelector } from '@/store/filterSystemsSlise'
import IconsSelector from '@/components/Molecules/IconsSelector/Icons'

const FILTERS = [
  { id: 0, name: 'Выбрать все' },
  { id: 1, name: 'Исправны' },
  { id: 2, name: 'Ошибки в работе' },
  { id: 3, name: 'Неисправны' },
]
const Filter = () => {
  const { deviceStateFilter } = useAppSelector(filterSystemSelector)
  const { setDeviceStateFilter, toggleOnChangeDevice } = useActions()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e: any) => {
    if (
      e.target.nodeName === 'LI' ||
      e.target.nodeName === 'INPUT' ||
      e.target.ariaLabel === 'menu'
    ) {
      return
    }
    setAnchorEl(null)
  }

  const onSelectedFilter = (id: number) => {
    let tmp = [...deviceStateFilter]
    if (id === 0) {
      setDeviceStateFilter([0])
    } else {
      tmp = tmp.filter((item) => item !== 0)
      if (deviceStateFilter.includes(id)) {
        tmp = tmp.filter((item) => item !== id)
        setDeviceStateFilter([...tmp])
      } else {
        setDeviceStateFilter([...tmp, id])
      }
    }
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: 450 }}
      >
        <MenuList>
          {FILTERS.map((item, index) => {
            const label = { inputProps: { 'aria-label': item.name } }
            return (
              <MenuItem
                key={index}
                id={item?.id?.toString()}
                onClick={handleClose}
                onClickCapture={() => onSelectedFilter(item.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    {...label}
                    checked={deviceStateFilter.includes(item.id)}
                  />
                </ListItemIcon>
                <Box
                  display='flex'
                  alignItems='center'
                  gap={1}
                  aria-label='menu'
                >
                  <IconsSelector id={item.id} />
                  {item.name}
                </Box>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
      <IconButton onClick={handleClick}>
        <Badge
          color={
            deviceStateFilter?.length > 0 && !deviceStateFilter.includes(0)
              ? 'green'
              : 'transparent'
          }
          variant='dot'
        >
          <FilterAltIcon
            htmlColor={'dark-gray'}
            fontSize='large'
          />
        </Badge>
      </IconButton>
    </>
  )
}

export default Filter
