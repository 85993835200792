import React from 'react'
import styles from './DITconstructor.module.scss'
import cn from 'classnames'
import RGL, { WidthProvider } from 'react-grid-layout'
import './DITConstructor.css'
import {
  ConstructorItemProps,
  ConstructorLayoutItem,
} from '@/components/Organisms/DIT/types'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
const ReactGridLayout = WidthProvider(RGL)
interface Props {
  constructorLayout: ConstructorLayoutItem[]
  handleConstructorLayout: (layout: ConstructorLayoutItem[]) => void
  constructorStyles: ConstructorItemProps[]
  width: number
  height: number
}
const DITConstructor = ({
  constructorLayout,
  handleConstructorLayout,
  constructorStyles,
  width,
  height,
}: Props) => {
  const { ditSetUnsavedChanges } = useActions()
  const [zoom, setZoom] = React.useState(1)
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const handleLayoutChange = (layout: ConstructorLayoutItem[]) => {
    console.log('layout', layout)
    handleConstructorLayout([...layout])
  }
  const onDragStop = (layout: ConstructorLayoutItem[]) => {
    layout.forEach((item) => {
      if (item.maxH && item.h + item.y > item.maxH) {
        item.y = item.maxH - item.h
      }
    })
    handleLayoutChange(layout)
  }
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const handleAnyChanges = () => {
    ditSetUnsavedChanges(true)
  }
  const getMaxDitZoom = (containerSize: number): number => {
    const result = Math.floor(containerSize / width)
    return result > 4 ? 4 : result
  }
  React.useLayoutEffect(() => {
    if (wrapperRef.current) {
      const newZoom = getMaxDitZoom(wrapperRef.current?.offsetWidth)
      if (zoom !== newZoom) {
        setZoom(newZoom)
      }
    }
    // window.addEventListener('resize', handleResize)
    // return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <>
      <div
        className={styles.GridWrapper}
        ref={wrapperRef}
      >
        <p>
          DIT size: w{width}, h{height}. Zoom: {zoom}
        </p>
        <ReactGridLayout
          style={{
            height: height,
            width: width,
            zoom: `${zoom}00%`,
            boxShadow: `0 0 0 ${1 / zoom}px deepskyblue`,
          }}
          onLayoutChange={handleLayoutChange}
          onDragStop={onDragStop}
          onResizeStop={onDragStop}
          onDrag={handleAnyChanges}
          onResize={handleAnyChanges}
          useCSSTransforms={true}
          allowOverlap={true}
          // isDraggable={true}
          // isResizable={true}
          layout={constructorLayout}
          rowHeight={1}
          cols={width}
          // autoSize={true}
          // Experiments
          transformScale={zoom}
          containerPadding={[0, 0]}
          margin={[0, 0]}
        >
          {constructorLayout.map((item, index) => {
            const current = constructorStyles.find((cur) => cur.i === item.i)
            if (!current) return null
            const isText = current.type === 'text'
            let itemStyle = {}
            if (isText) {
              itemStyle = {
                fontStyle: current.fontStyle,
                textDecoration: current.textDecoration,
                fontWeight: current.fontWeight,
                fontSize: `${current.fontSize}px`,
                textAlign: current.textAlign,
                color: current.color,
                zIndex: index,
                width: '100%',
                maxHeight: '100%',
              }
            }

            return (
              <div
                key={item.i}
                className={cn(styles.Item, {
                  [styles.ActiveItem]: item.i === activeSlideItem,
                })}
              >
                <p style={itemStyle}>
                  {isText ? (
                    current.text
                  ) : (
                    <img
                      className={styles.ItemImage}
                      src={current.imageUrl || ''}
                      loading={'lazy'}
                    />
                  )}
                </p>
              </div>
            )
          })}
        </ReactGridLayout>
      </div>
    </>
  )
}

export default DITConstructor
