import React, { useLayoutEffect, useRef } from 'react'
import cn from 'classnames'
import ModalVariable from '@/components/Organisms/DIT/ModalVariable/ModalVariable'
import styles from './DITbuttons.module.scss'
import { ConstructorStyleItem } from '@/components/Organisms/DIT/types'
import IconTextRight from '@/components/Atoms/Icons/IconTextRight'
import IconTextLeft from '@/components/Atoms/Icons/IconTextLeft'
import IconTextCenter from '@/components/Atoms/Icons/IconTextCenter'
import IconTextBold from '@/components/Atoms/Icons/IconTextBold'
import IconTextUnderlined from '@/components/Atoms/Icons/IconTextUnderlined'
import IconTextItalic from '@/components/Atoms/Icons/IconTextItalic'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
interface Props {
  activeItem: string
  activeSlide: number
  activeItemProps: ConstructorStyleItem
  handleConstructorProps: (style: ConstructorStyleItem) => void
}
type Variable = ConstructorStyleItem['params'][0]
const FONT_SIZES: number[] = []
for (let i = 12; i <= 40; i++) {
  if (!(i % 2)) {
    FONT_SIZES.push(i)
  }
}

const DITButtons = ({
  activeItemProps,
  activeItem,
  handleConstructorProps,
  activeSlide,
}: Props) => {
  const [text, setText] = React.useState<string>(activeItemProps.text)
  const [textBold, setTextBold] = React.useState<string>(
    activeItemProps.fontWeight
  )
  const hasChanges = useAppSelector(ditSliceSelectors.stateHasChanges)

  const { ditSetUnsavedChanges } = useActions()
  const [fontSize, setFontSize] = React.useState(activeItemProps.fontSize)
  const [textPosition, setTextPosition] = React.useState(
    activeItemProps.textAlign
  )
  const [textDecoration, setTextDecoration] = React.useState(
    activeItemProps.textDecoration
  )
  const [fontStyle, setFontStyle] = React.useState(activeItemProps.fontStyle)
  const [color, setColor] = React.useState(activeItemProps.color)

  const [showModal, setShowModal] = React.useState(false)
  const [variables, setVariables] = React.useState<Variable[]>([])

  React.useEffect(() => {
    if (activeItemProps) {
      setText(activeItemProps.text)
      setTextBold(activeItemProps.fontWeight)
      setFontSize(activeItemProps.fontSize)
      setTextPosition(activeItemProps.textAlign)
      setColor(activeItemProps.color)
      setTextDecoration(activeItemProps.textDecoration)
      setFontStyle(activeItemProps.fontStyle)
    }
  }, [activeItem, hasChanges, activeSlide])

  const handleClickBold = () => {
    const newValue = textBold === 'normal' ? 'bold' : 'normal'
    activeItemProps.fontWeight = newValue
    handleConstructorProps({ ...activeItemProps })
    setTextBold(newValue)
    ditSetUnsavedChanges(true)
  }
  const handleFontSize = (e: any) => {
    activeItemProps.fontSize = e.target.value
    handleConstructorProps({ ...activeItemProps })
    setFontSize(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleColor = (e: any) => {
    activeItemProps.color = e.target.value
    handleConstructorProps({ ...activeItemProps })
    setColor(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleClickTextAlign = (
    position: ConstructorStyleItem['textAlign']
  ) => {
    activeItemProps.textAlign = position
    handleConstructorProps({ ...activeItemProps })
    setTextPosition(position)
    ditSetUnsavedChanges(true)
  }
  const handleClickUnderline = () => {
    const newValue = textDecoration === 'none' ? 'underline' : 'none'
    activeItemProps.textDecoration = newValue
    handleConstructorProps({ ...activeItemProps })
    setTextDecoration(newValue)
    ditSetUnsavedChanges(true)
  }
  const handleClickItalic = () => {
    const newValue = fontStyle === 'normal' ? 'italic' : 'normal'
    activeItemProps.fontStyle = newValue
    handleConstructorProps({ ...activeItemProps })
    setFontStyle(newValue)
    ditSetUnsavedChanges(true)
  }

  const handlerTextChange = (e: any) => {
    const newText = e.target.value as string
    if (activeItemProps.params && activeItemProps.params.length) {
      const paramsLabels = activeItemProps.params.map((item) => item.label)
      paramsLabels.forEach((label) => {
        if (!newText.includes(label)) {
          const filteredVars = [...variables].filter(
            (item) => item.label !== label
          )
          setVariables(filteredVars)
          activeItemProps.params = filteredVars
        }
      })
    }
    activeItemProps.text = newText
    handleConstructorProps({ ...activeItemProps })
    setText(e.target.value)
    ditSetUnsavedChanges(true)
  }
  const handleAddVariable = () => {
    if (activeItemProps.params && activeItemProps.params.length) {
      alert('Доступна только 1 переменная для 1 блока')
    } else {
      setShowModal(true)
    }
  }

  const handleSetVariables = (variable: Variable) => {
    // TODO add if var is unique
    setVariables([...variables, variable])
    activeItemProps.text = `${text} ${variable.label}`
    activeItemProps.params = [...variables, variable]
    handleConstructorProps({ ...activeItemProps })
    setText(`${text} ${variable.label}`)
    ditSetUnsavedChanges(true)
  }
  return (
    <div className={styles.Wrapper}>
      <ModalVariable
        showModal={showModal}
        setShowModal={setShowModal}
        setVariables={handleSetVariables}
      />
      <div className={styles.ButtonsWrapper}>
        <div className={styles.ButtonsRow}>
          <button
            onClick={handleClickBold}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textBold !== 'normal',
            })}
          >
            <IconTextBold />
          </button>
          <button
            onClick={handleClickItalic}
            className={cn(styles.Button, {
              [styles.ButtonActive]: fontStyle !== 'normal',
            })}
          >
            <IconTextItalic />
          </button>
          <button
            onClick={handleClickUnderline}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textDecoration !== 'none',
            })}
          >
            <IconTextUnderlined />
          </button>
          <select
            className={styles.Select}
            value={fontSize}
            onChange={handleFontSize}
          >
            {FONT_SIZES.map((s) => (
              <option
                key={s}
                value={s}
              >
                {s}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.ButtonsRow}>
          <button
            onClick={() => handleClickTextAlign('left')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'left',
            })}
          >
            <IconTextLeft />
          </button>
          <button
            onClick={() => handleClickTextAlign('center')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'center',
            })}
          >
            <IconTextCenter />
          </button>
          <button
            onClick={() => handleClickTextAlign('right')}
            className={cn(styles.Button, {
              [styles.ButtonActive]: textPosition === 'right',
            })}
          >
            <IconTextRight />
          </button>
          <input
            className={styles.ColorButton}
            type='color'
            disabled={true}
            onChange={handleColor}
            value={color}
          />
        </div>
        <div className={styles.ButtonsRow}>
          <button
            onClick={handleAddVariable}
            className={cn(styles.Button, styles.ButtonBlock)}
          >
            Добавить переменную
          </button>
        </div>
      </div>
      <div className={styles.TextAreaWrapper}>
        <textarea
          className={styles.TextArea}
          onChange={handlerTextChange}
          value={text}
        />
      </div>
    </div>
  )
}

export default DITButtons
