import { IconButton, Stack, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'

const Header = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isNewUser = id === 'new'

  const { data } = useGetUserProfileQuery(id ?? '0', {
    skip: isNewUser,
  })

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={{ xl: 2 }}
    >
      <IconButton onClick={() => navigate(-1)}>
        <KeyboardBackspaceIcon />
      </IconButton>
      <Typography variant='h6'>
        {isNewUser ? (
          'Создать нового пользователя'
        ) : data ? (
          <span style={{ textTransform: 'capitalize' }}>{`${
            data?.data?.surName ?? ''
          } ${
            data?.data?.firstName ?? ''
          } ${data?.data?.patronymic ?? ''}`}</span>
        ) : (
          ''
        )}
      </Typography>
    </Stack>
  )
}

export default Header
