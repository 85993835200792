import { api } from '@/api'
import dayjs from 'dayjs'
import {
  AddNewUnitRes,
  NewUnitTypeRes,
  DitSizesRes,
  AddNewDitUnitRes,
} from '@/modules/add-new-unit/types/newUnitType'
import { ISimpleResponse } from '@/modules/login/types'

const newUnitService = api.injectEndpoints({
  endpoints: (build) => ({
    getNewUnit: build.query<NewUnitTypeRes, { id: number }>({
      query: ({ id }) => ({
        url: '/devices/getProperties',
        method: 'GET',
        params: { id },
      }),
      transformResponse: (res: NewUnitTypeRes) => {
        if (res.success) {
          res.data.lastConnectionDate = dayjs(
            new Date(res.data.lastConnectionDate)
          ).format('DD.MM.YYYY HH:mm:ss')
        }
        return res
      },
    }),
    addNewUnit: build.mutation<ISimpleResponse, AddNewUnitRes>({
      query: (body) => ({
        url: '/devices/updateProperties',
        method: 'POST',
        body,
      }),
    }),
    addNewDitUnit: build.mutation<ISimpleResponse, AddNewDitUnitRes>({
      query: (body) => ({
        url: '/devices/updateDitSingleColorProperties',
        method: 'POST',
        body,
      }),
    }),
    getDitSizes: build.query<DitSizesRes, { fullColor: boolean }>({
      query: ({ fullColor }) => ({
        url: '/dit/ditTypes',
        method: 'GET',
        params: { fullColor },
      }),
      // @ts-ignore
      transformResponse: (res: DitSizesRes) => {
        return res.success ? res.data : res
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNewUnitQuery,
  useGetDitSizesQuery,
  useAddNewUnitMutation,
  useAddNewDitUnitMutation,
} = newUnitService
