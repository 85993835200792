import battary100 from '@/assets/img/full-battery.png'
import battary0 from '@/assets/img/low-battery.png'
import battary50 from '@/assets/img/medium-battery.png'
import { voltage12, voltage24, VoltageEnum } from '@/utils'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

const Voltage = ({ voltage }: { voltage: number }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={0.5}
    >
      <Tooltip
        title={'Напряжение питания'}
        placement='top'
      >
        <img
          src={
            (voltage ?? 0) <= VoltageEnum.min
              ? voltage12(voltage ?? 0) === 'low'
                ? battary0
                : voltage12(voltage ?? 0) === 'medium'
                  ? battary50
                  : voltage12(voltage ?? 0) === 'high'
                    ? battary100
                    : battary0
              : (voltage ?? 0) > VoltageEnum.min &&
                  (voltage ?? 0) <= VoltageEnum.max
                ? voltage24(voltage ?? 0) === 'low'
                  ? battary0
                  : voltage24(voltage ?? 0) === 'medium'
                    ? battary50
                    : voltage24(voltage ?? 0) === 'high'
                      ? battary100
                      : battary0
                : battary0
          }
          height={25}
          width={25}
          alt='Сигнал'
        />
      </Tooltip>
      <span>{Number(voltage).toFixed(2)}B</span>
    </Box>
  )
}

export default Voltage
