import React, { SyntheticEvent, useState } from 'react'
import { Button } from '@mui/material'
import styles from '../DITConstructor/DITconstructor.module.scss'
import { useUploadDitImageMutation } from '../service'
import { LoadingButton } from '@mui/lab'
import {
  ConstructorImageItem,
  ConstructorItemProps,
  ConstructorStyleItem,
} from '@/components/Organisms/DIT/types'
import { useForm } from 'react-hook-form'
import { useActions } from '@/hooks'
import { useParams } from 'react-router-dom'
interface Props {
  activeItem: string
  activeItemProps: ConstructorImageItem
  handleConstructorProps: (style: ConstructorImageItem) => void
}

const DITImages = ({
  activeItemProps,
  activeItem,
  handleConstructorProps,
}: Props) => {
  const { id } = useParams()
  const { ditSetUnsavedChanges } = useActions()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  const [uploadedFile, setUploadedFile] = useState<string>('')
  const [addImage] = useUploadDitImageMutation()
  const handleAddPhoto = async (e: SyntheticEvent) => {
    const { files } = e.target as HTMLInputElement
    if (e.target && files?.length) {
      try {
        setLoading(true)
        const formData = new FormData()
        // @ts-ignore
        formData.append('deviceId', id)
        formData.append('image', files[0])
        const { data } = (await addImage(formData)) as any
        const itemVsImage = {
          ...activeItemProps,
          imageUrl: data.imageUrl,
          imageId: data.imageId,
        }
        handleConstructorProps(itemVsImage)
        ditSetUnsavedChanges(true)
      } catch (e) {
        console.log(e)
      }

      setLoading(false)
    }
  }

  return (
    <>
      <div className={styles.Wrapper}>
        {uploadedFile && (
          <img
            className={styles.UploadedImage}
            src={uploadedFile}
            alt=''
          />
        )}
        {!uploadedFile && activeItemProps.imageUrl && (
          <img
            className={styles.UploadedImage}
            src={activeItemProps.imageUrl}
            alt=''
          />
        )}
        <LoadingButton
          variant={'text'}
          component='label'
          loading={loading}
        >
          Загрузить изображение
          <form>
            <input
              id='photosInput'
              type='file'
              hidden
              accept='.jpg, .jpeg, .png, .gif'
              {...register('file', { onChange: handleAddPhoto })}
            />
          </form>
        </LoadingButton>
      </div>
    </>
  )
}

export default DITImages
