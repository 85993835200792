import ClearlIcon from '@mui/icons-material/Clear'

const Icon = () => {
  return (
    <ClearlIcon
      htmlColor='rgba(0, 0, 0, 0.54)'
      fontSize='small'
    />
  )
}

export default Icon
