import { PhotoItem } from '@/modules/device-module/types/deviceType'
import SwiperButtons from './SwiperButtons'
import ButtonCopyImage from './ButtonCopyImage'
import { useState } from 'react'
import type { Swiper as SwiperType } from 'swiper/types'
import styles from './Swiper.module.scss'
import PhotoFullScreen from './PhotoFullscrean'
import Loader from '@/components/Atoms/Loader/Loader'
export type PhotoBigProps = {
  photos: PhotoItem[]
  swiperRefBottom: SwiperType | null
  activeSlide: number
  slideLeft: () => void
  slideRight: () => void
}
const PhotoBig = ({
  photos,
  swiperRefBottom,
  activeSlide,
  slideLeft,
  slideRight,
}: PhotoBigProps) => {
  const [loader, setLoader] = useState(false)
  return (
    <>
      <div className={styles.MainImageWrapper}>
        <div className={styles.MainImage}>
          {loader && <Loader />}
          <img
            src={photos[activeSlide].bigImg}
            alt={photos[activeSlide].date}
            loading='lazy'
            onLoad={() => setLoader(false)}
          />
        </div>

        <SwiperButtons
          swiperRefBottom={swiperRefBottom}
          slideLeft={slideLeft}
          slideRight={slideRight}
        />

        <div className={styles.MainImageButtons}>
          <ButtonCopyImage
            bigImg={photos[activeSlide]?.bigImg}
            setLoader={setLoader}
          />
          <PhotoFullScreen
            src={photos[activeSlide]?.bigImg}
            setLoader={setLoader}
          />
        </div>
      </div>
    </>
  )
}

export default PhotoBig
