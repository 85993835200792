import { Dialog, notifySuccess } from '@/components'
import {
  useGetDescriptionQuery,
  useUpdateDescriptionMutation,
} from '@/modules/device-module/services/deviceService'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const DescrSystem = () => {
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const inputRef = React.useRef<any>(null)

  const [isOnChange, setIsOnChange] = React.useState(false)
  const [openDialogSave, setOpenDialogSave] = useState(false)

  const { id } = useParams()

  const { data, refetch } = useGetDescriptionQuery(Number(id))
  const [updateDescriptionService, { isLoading }] =
    useUpdateDescriptionMutation()

  const [value, setValue] = useState(
    'Г-образная опора светильник \n 220ВТ 12В СЭС 4020/20'
  )

  useEffect(() => {
    if (data) {
      setValue(() => data.data.description)
    }
  }, [data?.data.description, isOnChange])
  const onReset = () => {
    refetch()
    setIsOnChange(false)
  }

  const onSubmit = async () => {
    try {
      setOpenDialogSave(false)
      const res = await updateDescriptionService({
        deviceId: Number(id),
        description: value,
      }).unwrap()
      if (res.success) {
        notifySuccess('Описание системы успешно сохранено')
        setIsOnChange(!isOnChange)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      onClick={() => {
        inputRef.current?.focus()
      }}
    >
      <Dialog
        message='Сохранить внесенные изменения?'
        open={openDialogSave}
        handleClose={() => setOpenDialogSave(false)}
        handleAgree={onSubmit}
        loading={isLoading}
      />
      <Box
        display='flex'
        alignItems='center'
      >
        {currentUserType === UserTypes.user ? null : (
          <IconButton
            disabled={isOnChange}
            onClick={() => {
              setIsOnChange(!isOnChange)
              inputRef.current?.focus()
            }}
          >
            <EditIcon
              htmlColor={isOnChange ? '#5ebc69' : 'rgba(0, 114, 188, .7)'}
              fontSize='medium'
            />
          </IconButton>
        )}
        <Typography
          variant='h6'
          fontSize={18}
          marginLeft={1}
          color='primary'
        >
          Описание системы
        </Typography>
      </Box>
      <Paper
        sx={{
          flex: 1,
          overflow: 'hidden',
          cursor: isOnChange ? 'text' : 'default',
        }}
        onClick={() => {
          inputRef.current?.focus()
        }}
      >
        <TextField
          ref={inputRef}
          inputRef={(input) => input && input.focus()}
          fullWidth
          autoFocus
          disabled={!isOnChange}
          multiline
          sx={{
            height: 'calc(100vh - 362px)',
            overflow: 'auto',
            '& fieldset': { border: 'none' },
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Paper>
      {isOnChange ? (
        <Box
          pt={2}
          gap={2}
          display='flex'
          flexDirection='row'
        >
          <Button onClick={onReset}>Отменить</Button>
          <Button onClick={() => setOpenDialogSave(true)}>Сохранить</Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default DescrSystem
