import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import styles from './Swiper.module.scss'
import cn from 'classnames'
import type { SyntheticEvent } from 'react'
import type { PhotoBigProps } from './PhotoBig'

type SwiperButtonsProps = Pick<
  PhotoBigProps,
  'slideLeft' | 'slideRight' | 'swiperRefBottom'
>

const BTN_PREV = 'prev'
const BTN_NEXT = 'next'

const SwiperButtons = ({
  swiperRefBottom,
  slideLeft,
  slideRight,
}: SwiperButtonsProps) => {
  const clickHandler = (e: SyntheticEvent<HTMLButtonElement>) => {
    const target = e.currentTarget as HTMLButtonElement
    if (target.dataset.direction === BTN_PREV) {
      slideLeft()
      swiperRefBottom?.slidePrev()
    } else {
      slideRight()
      swiperRefBottom?.slideNext()
    }
  }
  return (
    <div className={styles.SwiperButtonsWrapper}>
      <button
        className={cn(styles.SwiperButtonsLeft, styles.SwiperButtons, BTN_PREV)}
        onClick={clickHandler}
        data-direction={BTN_PREV}
      >
        <ArrowBackIosIcon />
      </button>
      <button
        className={cn(
          styles.SwiperButtonsRight,
          styles.SwiperButtons,
          BTN_NEXT
        )}
        onClick={clickHandler}
        data-direction={BTN_NEXT}
      >
        <ArrowForwardIosIcon />
      </button>
    </div>
  )
}

export default SwiperButtons
