import iconLight from '@/assets/img/light-on.png'
import iconRightRed from '@/assets/img/right-red.png'
import { Box } from '@mui/material'
import * as React from 'react'
import { FC } from 'react'
import styles from './DevicesTable.module.scss'
import { TotalSmartDetailsType } from './DevicesStatus'
interface Props {
  totalDetails: TotalSmartDetailsType | undefined
}

interface ItemProps {
  total: number
  working: number
  braked: number
  type: 'lamp' | 'turn'
}

const Item: FC<ItemProps> = ({ total, working, braked, type }) => {
  return (
    <div className={styles.statusWrapper}>
      <img
        src={type === 'lamp' ? iconLight : iconRightRed}
        width={30}
        alt='proc'
      />
      <span className={styles.statusContent}>
        <span className={styles.statusContentTotal}>{total}</span>
        <span>
          <span className={styles.statusContentWorking}>{working}</span> /{' '}
          <span className={styles.statusContentTotalBraked}>{braked}</span>
        </span>
      </span>
    </div>
  )
}

const DevicesStatusContentPopover: FC<Props> = ({ totalDetails }) => {
  if (!totalDetails) return null
  const {
    totalSmartLampCountSuccess,
    totalSmartLampCountErrors,
    totalSmartTurnCountSuccess,
    totalSmartTurnCountErrors,
  } = totalDetails
  const totalSmartLamp = totalSmartLampCountSuccess + totalSmartLampCountErrors
  const totalSmartTurn = totalSmartTurnCountSuccess + totalSmartTurnCountErrors
  const items: ItemProps[] = []
  if (totalSmartLamp) {
    items.push({
      type: 'lamp',
      total: totalSmartLamp,
      working: totalSmartLampCountSuccess,
      braked: totalSmartLampCountErrors,
    })
  }
  if (totalSmartTurn) {
    items.push({
      type: 'turn',
      total: totalSmartTurn,
      working: totalSmartTurnCountSuccess,
      braked: totalSmartTurnCountErrors,
    })
  }
  return (
    <Box
      width={150}
      pt={2}
      display='flex'
      justifyContent='center'
      pb='12px'
    >
      <Box
        display='flex'
        gap={2}
        flexWrap='wrap'
        justifyContent='center'
        pl={0}
      >
        {items.map(({ total, type, working, braked }) => (
          <Item
            key={type}
            total={total}
            working={working}
            braked={braked}
            type={type}
          />
        ))}
      </Box>
    </Box>
  )
}

export default DevicesStatusContentPopover
