import {
  ConstructorItemProps,
  ConstructorLayoutItem,
  ConstructorStyleItem,
  SlideConstructor,
} from '@/components/Organisms/DIT/types'

export const extractLayoutStyles = (
  curItems: SlideConstructor[]
): ConstructorItemProps[] => {
  const res: ConstructorItemProps[] = []
  curItems.forEach((item) => {
    if (item.type === 'text') {
      res.push({
        i: item.i,
        type: item.type,
        color: item.color,
        textAlign: item.textAlign,
        fontWeight: item.fontWeight,
        fontSize: item.fontSize,
        fontStyle: item.fontStyle,
        textDecoration: item.textDecoration,
        text: item.text,
        name: item.name,
        params: item.params,
      })
    }
    if (item.type === 'image') {
      res.push({
        i: item.i,
        type: item.type,
        imageUrl: item.imageUrl,
        // TODO generate Id
        imageId: 'sgfa',
        name: item.name,
      })
    }
  })
  return res
}

export const extractLayoutItems = (
  curItems: SlideConstructor[],
  activeItem: string,
  maxW: number,
  maxH: number,
  isDisabled: boolean
): ConstructorLayoutItem[] => {
  const res: ConstructorLayoutItem[] = []
  curItems.forEach((item) => {
    res.push({
      i: item.i,
      w: item.w,
      h: item.h,
      maxH: maxH,
      maxW: maxW,
      x: item.x,
      y: item.y,
      // static: activeItem !== item.i,
      isBounded: true,
      isDraggable: activeItem === item.i && !isDisabled,
      isResizable: activeItem === item.i && !isDisabled,
    })
  })
  return res
}

export const combineItems = (
  layoutItem: ConstructorItemProps,
  constructorItems: SlideConstructor[]
): SlideConstructor[] => {
  return constructorItems.map((item) => {
    if (item.i === layoutItem.i) {
      item = { ...item, ...layoutItem }
    }
    return item
  })
}

export const combineLayout = (
  layoutItems: ConstructorLayoutItem[],
  constructorItems: SlideConstructor[]
): SlideConstructor[] => {
  return constructorItems.map((item) => {
    const curLayoutItem = layoutItems.find(
      (layoutItem) => layoutItem.i === item.i
    )
    if (item.i === curLayoutItem?.i) {
      item = { ...item, ...curLayoutItem }
    }
    return item
  })
}
