export enum Status {
  error = 3,
  warning = 2,
  ok = 1,
}

export enum ActivityStatus {
  off = 1,
  noActive = 4,
  noLoad = 3,
  on = 2,
}

export enum TurnStatus {
  off = 1, //черный
  on = 2, //красный
  noActive = 3, //красный бледный
}

export enum MeteoUnitsType {
  FWS = 1, //Датчик окружающей среды
  NIR = 2, //Датчик дорожного покрытия
  DSM = 3, //Датчик осадков
  VRE = 4, //Датчик видимости
}

export interface DeviceRes {
  success: boolean
  data: DeviceDetail
}

export interface DeviceDetail {
  id: number
  ditData: { width: number; height: number }
  typeId: number
  type: string
  smart: boolean
  is220v: boolean
  name: string
  address: string
  errorMessage: string
  mac: string
  favorite: boolean
  modulesCount: number
  deviceDate: string
  operator: string
  signal: number
  size: string
  connectionStatus: number
  lastPackageDate: string
  lastPhotoDate: string
  powerVoltage: number
  state: Status
  modules: DeviceModule[]
  modulesMeteo?: DeviceModuleMeteo[]
  meteo?: {
    dsm: {
      connectionStatus: number
      state: Status
      diameter: number
      displaySequence: number
      fallVelocity: number
      intensity: number
      lastPackageDate: string
      errorMessage: string
      moduleId: number
      name: string
      synop4677: number
      synop4680: number
      precipitationId: number
      type: MeteoUnitsType
    }
    fws: {
      connectionStatus: number
      state: Status
      directionWindAvg: number
      displaySequence: number
      humidity: number
      lastPackageDate: string
      errorMessage: string
      moduleId: number
      name: string
      pressureHg: number
      speedAvg: number
      speedMax: number
      temperature: number
      type: MeteoUnitsType
    }
    nir: {
      connectionStatus: number
      state: Status
      displaySequence: number
      iceAccumulation: number
      lastPackageDate: string
      errorMessage: string
      moduleId: number
      name: string
      pavementTemperature: number
      snowAccumulation: number
      type: MeteoUnitsType
      waterCumulative: number
      wetAndSlippery: number
    }
    vre: {
      avgSlow: number
      state: Status
      connectionStatus: number
      displaySequence: number
      lastPackageDate: string
      errorMessage: string
      moduleId: number
      name: string
      type: MeteoUnitsType
    }
  }
  photos?: PhotoItem[]
}

export type PhotoCameraGalleryQueryBase = {
  deviceId: string
  // lastPhotoDate remove ?
  lastPhotoDate: string | undefined
  startDate: string
  endDate: string
  isNew: boolean
}
// export type PhotoCameraGalleryQueryNew = {
//   isNew: true
// } & PhotoCameraGalleryQueryBase
//
// export type PhotoCameraGalleryExisting = {
//   isNew: false
//   firstPhotoDate: string
//   sourcePosition: string
// } & PhotoCameraGalleryQueryBase

export type PhotoCameraGalleryQuery = PhotoCameraGalleryQueryBase

export interface PhotoItem {
  id: number
  date: string
  bigImg: string
  smallImg: string
}

export interface DeviceModule {
  id: number
  name: string
  address: string
  connectionStatus: number
  lastPackageDate: string
  errorMessage: string
  powerVoltage: number
  state: Status
  leftTurn: boolean
  activitiStatus: ActivityStatus
  lastPowerOn: any
  lastPowerOff: any
  displaySequence: number
  rightTurnActivitiStatus: TurnStatus
  leftTurnActivitiStatus: TurnStatus
}

export interface DeviceModuleMeteo {
  id: number
  name: string
  errorMessage: string
  displaySequence: number
  moduleId: number
  type: number
  deleted: boolean
  units: Unit[]
  info: {
    state: number
    date: string
    connectionStatus: number
    message: string
  }
}

interface Unit {
  name: string
  icon: string
  value: string | number
  suffix?: string
}

export interface AddToFavorites {
  deviceId: number
  favoriteIds: number[]
}
export interface DeleteNotes {
  deviceId: number
  noteIds: number[]
}

export interface ChartDataResponse {
  success: boolean
  data: string
}

export interface ChartDataInterface {
  device: DeviceData[]
  data: DeviceData[]
  modules: Modules
  modulesVoltage?: ModulesVoltage[]
  modulesActivity?: ModulesVoltage[]
  modulesMeteo: ModulesVoltage[]
}

interface ModulesVoltage {
  data: { x: string; y: number }[]
  label: string
  borderColor: string
  tension: number
  pointRadius: number
  pointBackgroundColor: string
  yAxisID: string
  id: number
}

interface DeviceData {
  d: string
  v: number
  s: number
}

interface Modules {
  moduleId: number
  powerVoltage: PowerVoltage[]
}

interface PowerVoltage {
  d: string
  v: number
}

export interface DeviceUpdate {
  deviceId: number
  modules: ModulesUpdate[]
  deleted: number[]
}

export interface DeviceUpdateNewNote {
  deviceId: number
  message: string
  formData: any
}

export interface ModulesUpdate {
  moduleId: number
  name: string
  displaySequence: number
}

export interface MeteoSave {
  deviceId: number
  modules: MeteoSaveUnit[]
}

interface MeteoSaveUnit {
  moduleId: number
  type: number
  name: string
  displaySequence: number
  deleted: boolean
}
