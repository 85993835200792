import { FC } from 'react'
import iconModule from '@/assets/img/iconModule.png'
import { TypeIcon } from '@/components/Atoms/Icons/types'

const Icon: FC<TypeIcon> = ({ width = 25, height = 25 }) => {
  return (
    <img
      src={iconModule}
      alt='Module'
      style={{ opacity: '0.65' }}
      width={width}
      height={height}
    />
  )
}

export default Icon
