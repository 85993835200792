import { Dialog, notifySuccess } from '@/components'
import SectionItem from '@/modules/favorites/components/SectionItem'
import {
  favoritesSelector,
  removeFavorite,
  removeSystems,
  setFavorites,
} from '@/modules/favorites/store/favoritesSlise'
import { FavoritesAdd } from '@/modules/favorites/types/favorites'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import _ from 'lodash'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  useAddFavoritesMutation,
  useGetFavoritesWithDevicesQuery,
} from '../services/favoritesService'

interface Props {
  setLoader: Dispatch<SetStateAction<boolean>>
  setIsLenght: Dispatch<SetStateAction<boolean>>
}

const Sections: FC<Props> = ({ setLoader, setIsLenght }) => {
  const { data, isLoading } = useGetFavoritesWithDevicesQuery()
  const [addFavoritesService] = useAddFavoritesMutation()
  const { favorites } = useSelector(favoritesSelector)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [selected, setSelected] = useState<number>(0)
  const [selectedSystem, setSelectedSystem] = useState<number>(0)
  const [openDeleteSectionDialog, setOpenDeleteSectionDialog] = useState(false)
  const [openDeleteSystemDialog, setOpenDeleteSystemDialog] = useState(false)
  const [openSaveDialog, setOpenSaveDialog] = useState(false)

  useEffect(() => {
    setIsLenght(favorites[selected]?.devices.length > 12)
  }, [selected, favorites])

  useEffect(() => {
    if (isLoading) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (data?.data) {
      dispatch(setFavorites(data.data))
    }
  }, [pathname, data])

  const onSubmit = async () => {
    try {
      setLoader(true)
      setOpenSaveDialog(false)
      const data: FavoritesAdd[] = _.cloneDeep(favorites).map((item) => {
        return {
          id: item.id,
          name: item.name,
          displaySequence: item.displaySequence,
          devices:
            item.devices.length > 0
              ? item.devices.map((device) => device.id)
              : [],
        }
      })
      const dataFormData = new FormData()
      dataFormData.append('data', JSON.stringify(data))
      const res = await addFavoritesService(dataFormData).unwrap()
      if (res.success) {
        notifySuccess('Внесенные изменения сохранены')
      }
      setLoader(false)
    } catch (e) {
      console.log(e)
      setOpenSaveDialog(false)
      setLoader(false)
    }
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const updatedItems = _.cloneDeep(favorites)
    const [reorderedItem] = updatedItems.splice(result.source.index, 1)
    updatedItems.splice(result.destination.index, 0, reorderedItem)
    setSelected((prev) => result?.destination?.index ?? prev)
    updatedItems.forEach((item, index) => {
      item.displaySequence = index + 1
    })
    dispatch(setFavorites(updatedItems))
  }

  const getSecondaryAction = (index: number) => {
    if (selected === index) {
      return (
        <IconButton
          edge='end'
          onClick={() => setOpenDeleteSectionDialog(true)}
        >
          <ClearIcon htmlColor='red' />
        </IconButton>
      )
    }
    return <div />
  }

  const getSecondaryActionSystem = (index: number) => {
    if (selectedSystem === index) {
      return (
        <IconButton
          edge='end'
          onClick={() => {
            dispatch(
              removeSystems({ favoritesId: selected, systemId: selectedSystem })
            )
            setSelectedSystem(0)
          }}
        >
          <ClearIcon htmlColor='red' />
        </IconButton>
      )
    }
    return <div />
  }

  const onDeleteSection = () => {
    dispatch(removeFavorite(selected))
    setSelected(0)
    setOpenDeleteSectionDialog(false)
  }

  const onDeleteSystem = () => {
    dispatch(removeSystems({ favoritesId: selected, systemId: selectedSystem }))
    setSelectedSystem(0)
    setOpenDeleteSystemDialog(false)
  }

  return (
    <Box
      flex={1}
      width='100%'
      display='flex'
      flexDirection='column'
      height={{ xss: '70%', md: '100%' }}
    >
      <Dialog
        message='Вы действительно хотите удалить раздел?'
        open={openDeleteSectionDialog}
        handleClose={() => setOpenDeleteSectionDialog(false)}
        handleAgree={onDeleteSection}
      />
      <Dialog
        message='Вы действительно хотите удалить систему?'
        open={openDeleteSystemDialog}
        handleClose={() => setOpenDeleteSystemDialog(false)}
        handleAgree={onDeleteSystem}
      />
      <Dialog
        message='Внесенные изменения будут сохранены. Продолжить?'
        open={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
        handleAgree={onSubmit}
      />
      <Box
        display={{ xss: 'none', md: 'flex' }}
        gap={{ xss: 3, md: 25, xlg: 3 }}
      >
        <Typography
          variant='h6'
          sx={{ flex: 1, paddingLeft: 1 }}
        >
          Разделы
        </Typography>
        <Typography
          variant='h6'
          sx={{ flex: 2, paddingLeft: 1 }}
        >
          Системы
        </Typography>
      </Box>
      <Box
        flex={1}
        width='100%'
        //height='100%'
        display='flex'
        gap={{ xss: 1, md: 3 }}
        flexDirection={{ xss: 'column', md: 'row' }}
        sx={{ maxHeight: 'calc(100% - 110px)' }}
      >
        <Box display={{ xss: 'block', md: 'none' }}>
          <Typography
            variant='h6'
            sx={{ flex: 1, paddingLeft: 1, margin: 0, fontSize: '0.9rem' }}
          >
            Разделы
          </Typography>
        </Box>
        <Paper
          elevation={6}
          sx={{
            flex: { xss: 1, md: 1 },
            padding: 1,
            overflow: 'hidden',
            height: { xss: '50%', md: 'auto' },
          }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='favorites'>
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: snapshot.isDraggingOver
                      ? 'rgba(0, 114, 188, .1)'
                      : 'unset',
                    transition: 'background-color 300ms linear',
                    overflow: 'auto',
                  }}
                >
                  {favorites?.map(({ name, id }, index) => (
                    <SectionItem
                      key={index}
                      index={index}
                      selected={selected}
                      setSelected={setSelected}
                      setOpenDeleteSectionDialog={setOpenDeleteSectionDialog}
                      name={name}
                    />
                  ))}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
        <Box display={{ xss: 'block', md: 'none' }}>
          <Typography
            variant='h6'
            sx={{ flex: 1, paddingLeft: 1, margin: 0, fontSize: '0.9rem' }}
          >
            Системы
          </Typography>
        </Box>
        <Paper
          elevation={6}
          sx={{
            flex: { xss: 1, xlg: 2 },
            padding: 1,
            overflow: 'hidden',
            height: { xss: '50%', md: '100%' },
          }}
        >
          <List sx={{ width: '100%', overflow: 'auto', height: '100%' }}>
            {favorites[selected]?.devices?.map(({ name, address }, i) => (
              <ListItem
                key={i}
                secondaryAction={getSecondaryActionSystem(i)}
              >
                <ListItemButton
                  onClick={() => setSelectedSystem(i)}
                  selected={selectedSystem === i}
                >
                  <ListItemText primary={name + ' а/д ' + address} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
      <Box
        display='flex'
        justifyContent='flex-end'
        pt={2}
      >
        <Button
          sx={{ padding: '13px 35px' }}
          variant='outlined'
          onClick={() => setOpenSaveDialog(true)}
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  )
}

export default Sections
