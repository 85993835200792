import { PhotoItem } from '@/modules/device-module/types/deviceType'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { photos: PhotoItem[] } = {
  photos: [],
}

const MAX_PHOTOS = 180
const photoSliderSlice = createSlice({
  name: 'photoSlider',
  initialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<PhotoItem[]>) => {
      state.photos = action.payload
    },
    addPhotoBefore: (state, action: PayloadAction<PhotoItem[]>) => {
      // BE returns 60 photos
      // We don't want to have more than 120 photos on page - performance
      const newData = [...action.payload, ...state.photos]
      if (newData.length > MAX_PHOTOS) {
        const sliceFrom = newData.length - MAX_PHOTOS
        const full = newData.slice(sliceFrom, sliceFrom + MAX_PHOTOS)
        state.photos = [...full]
      } else {
        state.photos = newData
      }
      // state.photos = newData
    },
    addPhotoAfter: (state, action: PayloadAction<PhotoItem[]>) => {
      const newData = [...state.photos, ...action.payload]
      if (newData.length > MAX_PHOTOS) {
        const sliceFrom = newData.length - MAX_PHOTOS
        const full = newData.slice(sliceFrom, sliceFrom + MAX_PHOTOS)
        state.photos = [...full]
      } else {
        state.photos = newData
      }
      // state.photos = newData
    },
  },
})

export const { setPhotos, addPhotoBefore, addPhotoAfter } =
  photoSliderSlice.actions

export const photosSelector = (state: RootState) => state.photoSlider.photos

export default photoSliderSlice.reducer
