import CancelIcon from '@mui/icons-material/Cancel'

const Icon = () => {
  return (
    <CancelIcon
      htmlColor='rgb(221 0 0)'
      fontSize='medium'
    />
  )
}

export default Icon
