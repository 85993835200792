import cloudBlue from '@/assets/img/cloud-blue.png'
import cloudGray from '@/assets/img/cloud-gray.png'
import cloudRed from '@/assets/img/cloud-red.png'
import imgSize from '@/assets/img/size.png'
import electrical from '@/assets/img/electricalBlue.png'
import battary100 from '@/assets/img/full-battery.png'
import signal100 from '@/assets/img/full-connection.png'

import battary0 from '@/assets/img/low-battery.png'
import battary50 from '@/assets/img/medium-battery.png'
import signal50 from '@/assets/img/medium-connection.png'

import signal0 from '@/assets/img/no-connection.png'
import proc from '@/assets/img/processor.png'
import sim from '@/assets/img/sim-card.png'

import time from '@/assets/img/time.png'
import { useAppSelector } from '@/hooks'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'

import { signal, voltage12, voltage24, VoltageEnum } from '@/utils'
import cn from '@/utils/cn'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'

const s = cn('device-module-info-icons')

const InfoIconsDit = () => {
  const data = useAppSelector(infoIconsSelector)
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={{ xss: 0.5, lg: 1 }}
      className={s()}
    >
      <Box
        display='flex'
        alignItems='center'
        gap={2}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          width={{ xss: 140, lg: 160 }}
        >
          <Tooltip
            title={'Состояние подключения'}
            placement='top'
          >
            <img
              src={
                data?.connectionStatus === 1
                  ? cloudBlue
                  : data?.connectionStatus === 2
                    ? cloudGray
                    : data?.connectionStatus === 3
                      ? cloudRed
                      : cloudRed
              }
              height={25}
              width={25}
              alt='Связь'
            />
          </Tooltip>
          <span style={{ color: '#fff' }}>
            {data?.connectionStatus === 1
              ? 'В сети'
              : data?.connectionStatus === 2
                ? 'Не в сети'
                : data?.connectionStatus === 3
                  ? data?.lastPackageDate
                    ? dayjs(data?.lastPackageDate).format('DD.MM.YYYY HH:mm')
                    : 'Нет соединения'
                  : '-'}
          </span>
        </Box>
        {/*<Box*/}
        {/*  display='flex'*/}
        {/*  alignItems='center'*/}
        {/*  gap={0.5}*/}
        {/*  width={95}*/}
        {/*>*/}
        {/*  <Tooltip*/}
        {/*    title={'Оператор связи'}*/}
        {/*    placement='top'*/}
        {/*  >*/}
        {/*    <img*/}
        {/*      src={sim}*/}
        {/*      alt='Сим-карта'*/}
        {/*      width={25}*/}
        {/*      height={25}*/}
        {/*    />*/}
        {/*  </Tooltip>*/}
        {/*  <span style={{ color: '#fff', whiteSpace: 'nowrap' }}>*/}
        {/*    {data?.operator}*/}
        {/*  </span>*/}
        {/*</Box>*/}
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
        >
          <Tooltip
            title={'Размер'}
            placement='top'
          >
            <img
              src={imgSize}
              alt='Размер'
              width={25}
              height={25}
            />
          </Tooltip>
          <span style={{ color: '#fff', whiteSpace: 'nowrap' }}>
            {data?.size}
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default InfoIconsDit
