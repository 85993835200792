import EditlIcon from '@mui/icons-material/Edit'

const Icon = () => {
  return (
    <EditlIcon
      htmlColor='rgb(1 176 240)'
      fontSize='medium'
    />
  )
}

export default Icon
