import * as React from 'react'
import { copyImageToClipboardWithFetch } from '@/utils/screenShot'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import Tooltip from '@mui/material/Tooltip'
import { IconButton } from '@mui/material'
type Props = {
  bigImg: string
  setLoader: any
}
const ButtonCopyImage = ({ bigImg, setLoader }: Props) => {
  const copyHandler = () => {
    copyImageToClipboardWithFetch(bigImg, setLoader)
  }

  return (
    <Tooltip
      title={'Сохранить'}
      placement='top'
    >
      <IconButton onClick={copyHandler}>
        <ScreenshotMonitorIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonCopyImage
