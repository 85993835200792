import { useActions, useAppSelector } from '@/hooks'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import cn from '@/utils/cn'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Box } from '@mui/material'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import * as React from 'react'

const s = cn('device-module-calendar')
const Calendar = () => {
  const { fromDate, toDate, selectedTab, fromDateEvents, device } =
    useAppSelector(stateDeviceSelector)
  const { setToDate, setFromDate, setFromDateEvents } = useActions()
  const showByYear = selectedTab === 2 || device?.typeId === 7
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={{ xss: 0, md: 1 }}
      className={s()}
    >
      <DatePicker.RangePicker
        showTime
        allowClear={false}
        variant={'borderless'}
        suffixIcon={
          <CalendarMonthIcon
            htmlColor={'dark-gray'}
            fontSize='medium'
          />
        }
        style={{ width: 315 }}
        value={[showByYear ? fromDateEvents : fromDate, toDate]}
        format='DD.MM.YYYY HH:mm'
        onChange={(e) => {
          if (showByYear) {
            setFromDateEvents(dayjs(e ? e[0] : fromDate))
            setToDate(dayjs(e ? e[1] : toDate))
          } else {
            setFromDate(dayjs(e ? e[0] : fromDate))
            setToDate(dayjs(e ? e[1] : toDate))
          }
        }}
        disabledDate={(e) => {
          return showByYear ? false : e.isBefore(dayjs().subtract(2, 'month'))
        }}
      />
    </Box>
  )
}

export default Calendar
