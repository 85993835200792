import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  errorName: string
  errorMessage: string
  errorStack?: string
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorName: '',
    errorMessage: '',
    errorStack: '',
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMessage: _.message,
      errorName: _.name,
      errorStack: _.stack,
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>Sorry.. there was an error</p>
          {import.meta.env.MODE === 'development' && (
            <pre style={{ color: 'red', fontSize: 11, padding: 10 }}>
              <p>{this.state.errorName}</p>
              <p>{this.state.errorMessage}</p>
              <p>{this.state.errorStack}</p>
            </pre>
          )}
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
