import { FC } from 'react'
import { PortalWithState } from 'react-portal'
import styles from './Swiper.module.scss'
import * as React from 'react'
import CropFreeIcon from '@mui/icons-material/CropFree'
import { IconButton } from '@mui/material'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import Tooltip from '@mui/material/Tooltip'
import ButtonCopyImage from './ButtonCopyImage'
type Props = {
  src: string
  setLoader: any
}
const PhotoFullScreen: FC<Props> = ({ src, setLoader }) => {
  return (
    <PortalWithState
      closeOnOutsideClick
      closeOnEsc
    >
      {({ openPortal, closePortal, isOpen, portal }) => (
        <>
          <Tooltip
            title={'На весь экран'}
            placement='top'
          >
            <IconButton onClick={openPortal}>
              <CropFreeIcon />
            </IconButton>
          </Tooltip>
          {portal(
            <div className={styles.Portal}>
              <img
                src={src}
                width='100%'
                alt='img'
                style={{ aspectRatio: '16/9' }}
                loading='lazy'
                // onLoad={() => setLoaded(false)}
              />
              <div className={styles.PortalButtons}>
                <ButtonCopyImage
                  bigImg={src}
                  setLoader={setLoader}
                />
                <IconButton onClick={closePortal}>
                  <FitScreenIcon />
                </IconButton>
              </div>
            </div>
          )}
        </>
      )}
    </PortalWithState>
  )
}

export default PhotoFullScreen
