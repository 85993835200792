import { notifySuccess } from '@/components'
import { dataURLtoBlob } from '@/utils/dataURIToBlob'
import html2canvas from 'html2canvas'

export const handleScreenshot = (element: any, setLoader: any) => {
  if (element) {
    setLoader(true)
    html2canvas(element, { useCORS: true }).then((canvas) => {
      const screenshot = canvas.toDataURL()
      const blob = dataURLtoBlob(screenshot)
      const clipboardItem = new ClipboardItem({ 'image/png': blob })
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          notifySuccess('Снимок скопирован в буфер обмена')
          setLoader(false)
        })
        .catch((error) => {
          console.error('Ошибка копирования в буфер обмена:', error)
          setLoader(false)
        })
    })
  }
}

export const copyImageToClipboard = async (
  imageUrl: string,
  setLoader: any
) => {
  try {
    setLoader(true)
    const response = await fetch(imageUrl, {
      credentials: 'include',
    })
    const blob = await response.body
    console.log(blob)

    setLoader(false)
    notifySuccess('Изображение скопировано в буфер обмена')
  } catch (error) {
    console.error('Ошибка при копировании изображения:', error)
    setLoader(false)
  }
}

export const copyImageToClipboardCanvasMethod = async (
  imageUrl: string,
  setLoader: (loading: boolean) => void
) => {
  setLoader(true)

  const image = new Image()
  image.crossOrigin = 'anonymous'

  image.onload = async () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = image.width
    canvas.height = image.height
    ctx?.drawImage(image, 0, 0)

    canvas.toBlob(async (blob) => {
      try {
        const clipboardItem = new ClipboardItem({ 'image/png': blob as Blob })
        await navigator.clipboard.write([clipboardItem])
        notifySuccess('Изображение скопировано в буфер обмена')
      } catch (error) {
        console.error('Ошибка при копировании изображения:', error)
      } finally {
        setLoader(false)
      }
    }, 'image/png')
  }

  image.onerror = (error) => {
    console.error('Ошибка загрузки изображения:', error)
    setLoader(false)
  }

  image.src = imageUrl
}

export async function copyImageToClipboardWithFetch(
  imageUrl: string,
  setLoader: (loading: boolean) => void
) {
  try {
    setLoader(true)
    const response = await fetch(imageUrl, {
      credentials: 'include',
    })
    const imageBlob = await response.blob()

    const imageObjectUrl = URL.createObjectURL(imageBlob)

    const image = new Image()
    image.src = imageObjectUrl
    image.onload = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      ctx?.drawImage(image, 0, 0)

      const screenshot = canvas.toDataURL()
      const blob = dataURLtoBlob(screenshot)
      const clipboardItem = new ClipboardItem({ 'image/png': blob })
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          notifySuccess('Снимок скопирован в буфер обмена')
          setLoader(false)
        })
        .catch((error) => {
          console.error('Ошибка копирования в буфер обмена:', error)
          setLoader(false)
        })

      URL.revokeObjectURL(imageObjectUrl)
    }

    image.onerror = () => {
      console.error('Ошибка загрузки изображения')
      setLoader(false)
    }
  } catch (error) {
    console.error('Ошибка при получении изображения:', error)
    setLoader(false)
  }
}
