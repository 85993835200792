// @ts-nocheck
import { Dialog, notifyError, notifySuccess } from '@/components'
import UnitSelect from '@/modules/add-new-unit/components/UnitSelect/UnitSelect'
import {
  useAddNewUnitMutation,
  useGetNewUnitQuery,
  useGetDitSizesQuery,
} from '@/modules/add-new-unit/services/newUnitService'
import { AddNewUnitRes } from '@/modules/add-new-unit/types/newUnitType'
import { useGetDeviceDetailQuery } from '@/modules/device-module/services/deviceService'
import { UnitSearchReq } from '@/modules/devices-list/types/newUnitsType'
import { useGetUnitsHomeMapMutation } from '@/modules/maps/services/homeMapService'
import Input from '@/modules/organization/components/Input/Input'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { useGetUnitsTypesListQuery } from '@/services/unitsListService'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import TimeCalendar from '@/modules/aqua-device-module/components/actions/ui/TimeCalendar'
import DeleteDeviceButton from '@/modules/device-module/components/deleteDeviceButton/deleteDeviceButton'
import { useChangeSet } from '@/utils/useChangeSet'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Map from '@/components/Organisms/Map/Map'
const s = cn('device-module-feature')

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Название не может быть пустым')
    .trim('Название не может быть пустым')
    .max(30, 'Поле должно содержать не более 30 символов'),
  ip: Yup.string()
    .required('IP Адрес не может быть пустым')
    .trim('IP Адрес не может быть пустым')
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: 'Недопустимые символы',
      excludeEmptyString: true,
    })
    .test('ip', 'Адрес имеет некорректный формат', (value) => {
      return value === undefined || value.trim() === ''
        ? true
        : value.split('.').find((i) => parseInt(i, 10) > 255) === undefined
    })
    .max(15, 'IP Адрес должен содержать не более 15 символов'),

  size: Yup.string().required('Укажите размер'),
  port: Yup.number()
    .required('Порт не может быть пустым')
    .typeError('Недопустимые символы')
    .moreThan(9999, 'Порт должен содержать не менее 5 символов')
    .lessThan(99999, 'Порт должен содержать не более 5 символов'),
  address: Yup.string()
    .required('Адрес не может быть пустым')
    .trim('Адрес не может быть пустым')
    .max(250, 'Поле должно содержать не более 250 символов'),
})

export interface IDataFields {
  name: string
  ip: string
  port: string
  address: string
  size: string
  brightness: string
}

interface IUnitsFields {
  name: string
  ip: string
  port: string
  address: string
  size: string
  brightness: string
}

const defaultValues: IDataFields = {
  name: '',
  address: '',
  size: '',
  port: '',
  ip: '',
  brightness: '',
}

const BRIGHTNESS = []
for (let i = 1; i <= 10; i++) {
  BRIGHTNESS.push({ id: i, name: `${i * 10}%` })
}

const defaultPoint = [47.06587193746529, 39.435380396518724]

const FeaturesDit = () => {
  const { id } = useParams()

  const { data } = useGetDeviceDetailQuery(Number(id))
  const { data: unitsTypesData, isLoading: isLoadingUnitsTypesList } =
    useGetUnitsTypesListQuery(
      {
        isNew: true,
        isAll: false,
        isSmart: data?.smart,
        tsodd: data?.typeId === 5 || data?.typeId === 6,
      },
      { skip: !data }
    )

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { data: properties, refetch } = useGetNewUnitQuery(
    { id: Number(id) },
    {
      skip: currentUserType !== UserTypes.superAdmin,
    }
  )
  const { data: ditSizesData } = useGetDitSizesQuery({ fullColor: false })
  const [getDataUnits, { data: dataUnits }] = useGetUnitsHomeMapMutation()
  const [updateProperties] = useAddNewUnitMutation()
  const [disSizes, setDisSizes] = useState<{ id: number; name: string }[]>([])
  const [openDialogSave, setOpenDialogSave] = useState(false)
  const [loader, setLoader] = useState(false)
  const [newCoords, setNewCoords] = useState<number[]>(defaultPoint)
  const [center, setCenter] = useState<number[]>([47.2313, 39.7233])

  const onGetUnits = async () => {
    try {
      const usersSearchData: Omit<UnitSearchReq, 'page'> = {
        value: '',
        deviceTypeFilter: [0],
        deviceStateFilter: [0],
        favoriteId: 0,
      }
      await getDataUnits(usersSearchData).unwrap()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    onGetUnits().then()
  }, [])
  useEffect(() => {
    if (ditSizesData) {
      // @ts-ignore
      setDisSizes(ditSizesData)
    }
  }, [ditSizesData])

  const { handleSubmit, control, reset, trigger, formState, watch, getValues } =
    useForm<IDataFields>({
      defaultValues,
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      reValidateMode: 'onChange',
    })

  useEffect(() => {
    reset({
      name: properties?.data.name,
      ip: properties?.data.ip,
      port: properties?.data.port,
      size: properties?.data.sizeTypeId,
      address: properties?.data.address,
    })
    setNewCoords(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
    setCenter(() => [
      properties?.data.latitude ?? 47.2313,
      properties?.data.longitude ?? 39.7233,
    ])
  }, [properties])

  const onSaveBtnClick = () => {
    trigger().then(() => {
      if (
        !getValues('name') ||
        !getValues('address') ||
        !getValues('typeId') ||
        !getValues('imei')
      ) {
        return
      }
      if (
        !formState.errors.name &&
        !formState.errors.address &&
        !formState.errors.imei &&
        !formState.errors.typeId
      ) {
        setOpenDialogSave(true)
      }
    })
  }

  const onSubmit = async (dataForm: IUnitsFields) => {
    try {
      setOpenDialogSave(false)
      setLoader(true)
      const data: AddNewUnitRes = {
        id: Number(id),
        typeId: dataForm.typeId,
        name: dataForm.name,
        address: dataForm.address,
        coordinates: JSON.stringify(newCoords),
        imei: dataForm.imei,
        powerTypeId: dataForm.powerTypeId,
      }
      const res = await updateProperties(data).unwrap()
      if (res.success) {
        notifySuccess('Данные успешно сохранены')
        refetch()
      }
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }

  useChangeSet(() => {
    if (watch('name')?.length) {
      trigger('name')
    }
    if (watch('address')?.length) {
      trigger('address')
    }
    if (watch('ip')) {
      trigger('ip')
    }
    if (watch('port')?.length) {
      trigger('port')
    }
  }, [watch('name'), watch('address'), watch('ip'), watch('port')])
  console.log(properties?.data)
  return (
    <>
      <CircularProgress
        className={loader ? s('show-loader') : s('hide-loader')}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection={{ xss: 'column', md: 'row' }}
        className={s('aqua', { loading: loader })}
      >
        <Dialog
          message='Сохранить внесенные изменения?'
          open={openDialogSave}
          handleClose={() => setOpenDialogSave(false)}
          handleAgree={() => {
            if (Object.keys(formState.errors)?.length >= 1) {
              notifyError('Исправте ошибки в форме')
            } else {
              handleSubmit(onSubmit)()
            }
          }}
        />
        {/*Блок карты*/}
        <Box
          flex={1}
          display='flex'
          border='1px solid #0072BC'
          className={s('map-container')}
          mt={{ xss: 0, lg: '35px', xlgx: '-48px' }}
          height={{
            mdx: '100%',
            lg: 'calc(100% - 35px)',
            xlgx: 'calc(100% + 48px)',
          }}
        >
          <Map
            center={center}
            setNewCoords={setNewCoords}
            dataUnits={dataUnits}
            newCoords={newCoords}
            draggable={false}
          />
        </Box>
        {/*Блок информации*/}
        <Box
          maxWidth={{ xss: '100%', md: 550 }}
          maxHeight={'calc(100vh - 245px)'}
          overflow={'auto'}
          display='flex'
          flex={{ xss: 1, xl4: 0.7, xll: 0.53 }}
        >
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Box
              display='flex'
              flex={1}
              flexDirection={{ xss: 'column', md: 'column' }}
              pb={{ xss: 0.5, lg: 1 }}
            >
              <Box
                display='flex'
                flexDirection={{ xss: 'column', xlg: 'row' }}
              >
                <Box
                  flex={1}
                  px={1}
                >
                  <Typography
                    variant='h6'
                    fontSize={18}
                    color='primary'
                  >
                    Информация о системе
                  </Typography>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={1.5}
                  >
                    <Input
                      name='name'
                      type='text'
                      label='Имя'
                      control={control}
                    />
                    <Input
                      name='address'
                      type='text'
                      label='Адрес'
                      control={control}
                    />
                    <UnitSelect
                      items={disSizes ?? []}
                      name='size'
                      label='Выберите Размер'
                      control={control}
                    />
                    <Input
                      name='ip'
                      type='text'
                      label='IP адрес'
                      control={control}
                    />
                    <Input
                      name='port'
                      type='text'
                      label='Порт'
                      control={control}
                    />
                    <UnitSelect
                      items={BRIGHTNESS}
                      name='brightness'
                      label='Яркость'
                      control={control}
                    />
                    <TimeCalendar />
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='flex-start'
                justifyContent='flex-end'
                gap={2}
                //height={{ xss: 40, lg: 60 }}
                mr={{ xss: 0.5, lg: 2 }}
                pt={1}
              >
                {currentUserType !== UserTypes.superAdmin ? null : (
                  <>{id && <DeleteDeviceButton deviceId={id} />}</>
                )}
                <LoadingButton
                  loading={false}
                  variant='outlined'
                  onClick={onSaveBtnClick}
                  disabled={true}
                >
                  <span>Сохранить</span>
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default FeaturesDit
