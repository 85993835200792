import { IconButton, Stack, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOrganizationProfileQuery } from '@/modules/organization/services/organizationService'

const Header = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isNewOrganization = id === 'new'

  const { data } = useGetOrganizationProfileQuery(id ?? '0', {
    skip: isNewOrganization,
  })

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={{ xl: 2 }}
    >
      <IconButton onClick={() => navigate(-1)}>
        <KeyboardBackspaceIcon />
      </IconButton>
      <Typography variant='h6'>
        {isNewOrganization ? (
          'Создать новую организацию'
        ) : data ? (
          <span>{`${data?.data?.name ?? ''}`}</span>
        ) : (
          ''
        )}
      </Typography>
    </Stack>
  )
}

export default Header
