import React, { Suspense } from 'react'

const VideoComponent = React.lazy(() => import('./VideoComponent'))

function LazyVideoComponent() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <VideoComponent />
      </Suspense>
    </>
  )
}

export default LazyVideoComponent
