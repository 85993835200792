import { notifySuccess } from '@/components'
import { useActions, useAppSelector } from '@/hooks'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import {
  terminalHistoryNewSelector,
  triggerLoadHistorySelector,
} from '@/modules/device-module/store/terminalNewSlice'
import { TerminalHistory } from '@/modules/device-module/types/terminal'
import { TerminalHistoryNew } from '@/modules/device-module/types/terminal-new'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  useMediaQuery,
} from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSetTerminalCommandsMutation } from '../../services/deviceService'

interface Props {
  value: string
  setValue: (value: string) => void
}

const Notes = ({ value, setValue }: Props) => {
  const isMobile = useMediaQuery('(max-width:768px)')

  const { id } = useParams()
  const { selectedTab } = useAppSelector(stateDeviceSelector)
  const { addOneTerminalHistory, triggerLoadHistory } = useActions()
  const messageListRef = useRef<any>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const commandIndexRef = useRef<number | null>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [commandHistory, setCommandHistory] = useState<string[]>([])

  const data = useAppSelector(terminalHistoryNewSelector)
  const triggerLoadHist = useAppSelector(triggerLoadHistorySelector)

  const [setCommandService, { isLoading: isLoadingUpdate }] =
    useSetTerminalCommandsMutation()

  useEffect(() => {
    const history = data
      ? _.cloneDeep(data)
          .sort((a: TerminalHistoryNew, b: TerminalHistoryNew) => a.id - b.id)
          .map((item) => item.message)
      : []
    setCommandHistory(() => history ?? [])
    commandIndexRef.current = history?.length - 1
  }, [data, id, selectedTab])

  useEffect(() => {
    if (inputRef.current && value) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(value?.length, value?.length)
    }
  }, [value])

  const scrollToBottom = () => {
    const messageList = messageListRef.current
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight
    }
  }

  const onSubmit = async () => {
    if (value.trim() === '' || !isFocused) {
      return
    }
    try {
      const oneHistory: TerminalHistory = {
        id: 0,
        message: value,
        createdDate: dayjs(new Date()).format('DD.MM.YY HH:mm'),
        response: false,
        typeId: 1,
      }
      addOneTerminalHistory(oneHistory)
      const res = await setCommandService({
        command: value,
        deviceId: Number(id),
      }).unwrap()
      triggerLoadHistory()
      if (res.success) {
        notifySuccess('Команда добавлена')
        setCommandHistory((prevHistory: any) => {
          const newHistory = [...prevHistory, setValue]
          if (newHistory?.length > 5) {
            newHistory.shift()
          }
          return newHistory
        })
        setValue('')
        commandIndexRef.current = null
      }
      inputRef.current?.focus()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [data?.length])

  useEffect(() => {
    return () => {
      setIsFocused(false)
    }
  }, [])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setValue(
        commandIndexRef.current !== null
          ? commandHistory[commandIndexRef.current]
          : ''
      )
      if (commandIndexRef.current === null) {
        commandIndexRef.current = commandHistory?.length - 1
      } else if (commandIndexRef.current > 0) {
        commandIndexRef.current--
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (
        commandIndexRef.current === null ||
        commandIndexRef.current === commandHistory?.length - 1
      ) {
        commandIndexRef.current = null
        setValue('')
      } else if (commandIndexRef.current < commandHistory?.length - 1) {
        commandIndexRef.current++
        setValue(commandHistory[commandIndexRef.current])
      }
    } else if (
      event.key === 'Enter' ||
      event.charCode === 13 ||
      event.code === 'NumpadEnter'
    ) {
      event.preventDefault()
      onSubmit()
    }
  }

  const createMarkup = (commentText: string) => {
    return { __html: commentText }
  }

  return (
    <Box
      flex={{ xss: 1, lg: 3 }}
      display='flex'
      flexDirection='column'
    >
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          gap: { xss: 0.5, lg: 1 },
        }}
      >
        <Box
          flex={1}
          maxHeight={{ xss: 'calc(100vh - 600px)', lg: 'calc(100vh - 365px)' }}
          overflow='auto'
          justifyContent='space-between'
          px={1}
          display='flex'
          ref={messageListRef}
        >
          <Box
            flex={1}
            display='flex'
            gap={2}
            flexDirection='column'
          >
            <Box
              display='flex'
              gap={2}
              flexDirection='column'
            >
              {data?.map((item) => (
                <Box
                  key={item.id}
                  sx={{ lineBreak: 'anywhere' }}
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <Box display='flex'>
                    <span
                      style={{
                        color:
                          item.typeId === 3 ? 'rgba(0, 0, 0, 0.87)' : '#0072BC',
                      }}
                    >
                      {item.createdDate}
                    </span>
                    <div
                      style={{
                        color:
                          item.typeId === 3 ? 'rgba(0, 0, 0, 0.87)' : '#0072BC',
                        marginLeft: 20,
                      }}
                      dangerouslySetInnerHTML={createMarkup(item.message)}
                    />
                  </Box>
                  {item.typeId === 1 ? <CircularProgress size={15} /> : null}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          display='flex'
          gap={1}
          alignItems='center'
          px={1}
          mb={1}
        >
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            //size='small'
            //fullWidth
            placeholder='Введите сообщение'
            onKeyDown={handleKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={isLoadingUpdate}
            style={{
              width: '100%',
              height: 40,
              paddingInline: 14,
              borderRadius: 4,
              color: 'rgba(0, 0, 0, 0.87)',
              borderColor: 'rgba(0, 0, 0, 0.22)',
              fontSize: '1rem',
            }}
          />
          {isLoadingUpdate ? (
            <CircularProgress
              size={25}
              sx={{ mx: '9px' }}
            />
          ) : (
            <IconButton onMouseDown={onSubmit}>
              <ForwardToInboxIcon
                htmlColor='rgba(0, 114, 188, .7)'
                fontSize='medium'
              />
            </IconButton>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default Notes
