import AttachFileIcon from '@mui/icons-material/AttachFile'

const Icon = () => {
  return (
    <AttachFileIcon
      htmlColor='rgba(0, 114, 188, .7)'
      fontSize='medium'
    />
  )
}

export default Icon
