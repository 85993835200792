import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  useGetDitsQuery,
  useUpdateDitsMutation,
} from '@/components/Organisms/DIT/service'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useParams } from 'react-router-dom'

const ModalSave: React.FC<{
  showModal: boolean
  setShowModal: (state: boolean) => void
}> = ({ showModal, setShowModal }) => {
  const { id } = useParams()
  const programsState = useAppSelector(ditSliceSelectors.stateDitSlice)
  const [saveDIts] = useUpdateDitsMutation()
  const { refetch } = useGetDitsQuery(Number(id))
  // const res = useUpdateDitsMutation(programsState.programList)
  // console.log(res)
  const handleSave = async () => {
    setShowModal(false)
    await saveDIts({
      deviceId: Number(id),
      programs: programsState.programList,
    })
  }
  const handleClose = () => {
    setShowModal(false)
    // refetch()
  }

  const handleCancel = async () => {
    setShowModal(false)
    await refetch()
  }

  return (
    <Dialog open={showModal}>
      <DialogTitle>Сохранить изменения в настройках слайда?</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant='outlined'
        >
          Нет
        </Button>
        <LoadingButton
          onClick={handleSave}
          variant='contained'
        >
          Да
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ModalSave
